<ng-container *transloco="let t">
  @if (displaySubscriptionPaymentAlert) {
    <pxw-alert type="danger" [routerLink]="['./suscripcion']" class="subscription-alert__alert">
      <div class="alert-heading">{{ t('paymentAlert.requiresPaymentAlert1') }}</div>
      <div class="alert-body">
        <div [innerHTML]="t('paymentAlert.requiresPaymentAlert2')"></div>
        <div [innerHTML]="t('paymentAlert.goToSubscription')"></div>
      </div>
    </pxw-alert>
  }
  @if (displaySubscriptionInactiveAlert) {
    <pxw-alert type="warning" [routerLink]="['./suscripcion']" class="subscription-alert__alert">
      <div class="alert-heading">{{ t('paymentAlert.inactiveSubscriptionAlert1') }}</div>
      <div class="alert-body">
        <div [innerHTML]="t('paymentAlert.inactiveSubscriptionAlert2')"></div>
        @if (subscription.countryCode !== 'AR') {
          <div [innerHTML]="t('paymentAlert.goToSubscription')"></div>
        }
      </div>
      <ng-template #alertDetails>
        @if (subscription.countryCode === 'AR') {
          <pxw-button
            color="pink"
            [href]="reactivateSubscriptionLink"
            target="_blank"
            [rounded]="true"
            size="md"
          >
            <pxw-icon icon="external-link" />
            {{ t('paymentAlert.solveNow') }}</pxw-button
          >
        }
      </ng-template>
    </pxw-alert>
  }
</ng-container>
