import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { Establishment } from '@pedix-workspace/utils';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { RouterLink } from '@angular/router';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';

import { TranslocoDirective } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';
import { IconExternalLinkComponent } from '@pedix-workspace/angular-ui-icons';

@Component({
  selector: 'app-subscription-alert',
  templateUrl: './subscription-alert.component.html',
  styleUrls: ['./subscription-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    AlertComponent,
    RouterLink,
    ButtonComponent,
    IconExternalLinkComponent,
  ],
})
export class SubscriptionAlertComponent implements OnInit {
  @Input() subscription: Establishment['subscription'];
  reactivateSubscriptionLink: string;

  ngOnInit(): void {
    this.reactivateSubscriptionLink = `${environment.subscriptionUrl}/reactivacion/${this.subscription.id}`;
  }

  get displaySubscriptionPaymentAlert() {
    return (
      this.subscription &&
      this.subscription.status === 'active' &&
      this.subscription.requiresPayment
    );
  }

  get displaySubscriptionInactiveAlert() {
    return (
      this.subscription && (this.subscription.status === 'inactive' || this.subscription.canceledAt)
    );
  }
}
