import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  inject,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  IconCellphoneComponent,
  IconCloseComponent,
  IconDesktopComponent,
  IconExternalLinkComponent,
  IconLoadingComponent,
  IconTabletComponent,
} from '@pedix-workspace/angular-ui-icons';
import { NgClass } from '@angular/common';

@Component({
  selector: 'pxw-device-previewer',
  templateUrl: './device-previewer.component.html',
  styleUrls: ['./device-previewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    IconCellphoneComponent,
    IconTabletComponent,
    IconDesktopComponent,
    IconExternalLinkComponent,
    IconLoadingComponent,
    IconCloseComponent,
  ],
})
export class DevicePreviewerComponent {
  @Input() selectedPreviewType: 'cellphone' | 'tablet' | 'desktop' = 'cellphone';
  @Input() showControls = true;
  @Input() set iframeUrl(iframeUrl: string) {
    this.loading = true;
    this.safeIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
  }
  @Input() externalLinkUrl: string;

  @Output() closed = new EventEmitter();

  safeIframeUrl: SafeUrl;
  loading = false;

  private sanitizer = inject(DomSanitizer);

  constructor() {}

  onIframeLoaded() {
    this.loading = false;
  }
}
