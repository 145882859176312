import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  Input,
  ContentChild,
  AfterContentInit,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconColor, IconComponent } from '@pedix-workspace/angular-ui-icons';

import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'pxw-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, NgTemplateOutlet],
})
export class ListItemComponent implements AfterContentInit {
  @ContentChild(IconComponent) icon: IconComponent;

  @Input() color: IconColor;
  @Input() routerLink: RouterLink['routerLink'];
  @Input() href: string;

  @HostBinding('attr.role') role = 'listitem';

  ngAfterContentInit(): void {
    if (this.icon && this.color) {
      this.icon.color = this.color;
    }
  }
}
