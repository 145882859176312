import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="table"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconTableComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.61936 39H37.3806C39.3767 39 41 37.3555 41 35.3333V9.66667C41 7.6445 39.3767 6 37.3806 6H8.61936C6.62328 6 5 7.6445 5 9.66667V35.3333C5 37.3555 6.62328 39 8.61936 39ZM8.61936 35.3333V26.1667H15.8581V35.3333H8.61936ZM26.7162 13.3333V22.5H19.4775V13.3333H26.7162ZM15.8581 13.3333V22.5H8.61936V13.3333H15.8581ZM19.4775 35.3333V26.1667H26.7162V35.3333H19.4775ZM30.3355 35.3333V26.1667H37.3824V35.3333H30.3355ZM37.3806 22.5H30.3355V13.3333H37.3806V22.5Z"
    />
  </svg>`,
})
export class IconTableComponent extends IconComponent {}
