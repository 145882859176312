import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="notification"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconNotificationComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9763 32.1341V30.6341C16.1479 30.6341 15.4763 31.3057 15.4763 32.1341H16.9763ZM27.748 32.1341H29.248C29.248 31.3057 28.5765 30.6341 27.748 30.6341V32.1341ZM16.9763 33.6341H27.748V30.6341H16.9763V33.6341ZM26.248 32.1341V33.9294H29.248V32.1341H26.248ZM18.4763 33.9294V32.1341H15.4763V33.9294H18.4763ZM22.3622 37.8152C20.2161 37.8152 18.4763 36.0755 18.4763 33.9294H15.4763C15.4763 37.7323 18.5592 40.8152 22.3622 40.8152V37.8152ZM26.248 33.9294C26.248 36.0755 24.5083 37.8152 22.3622 37.8152V40.8152C26.1651 40.8152 29.248 37.7323 29.248 33.9294H26.248Z"
    />
    <path
      d="M8.52583 28.0177L7.46517 26.957H7.46517L8.52583 28.0177ZM9.4429 27.1006L10.5036 28.1612L10.5036 28.1612L9.4429 27.1006ZM35.2817 27.1005L36.3424 26.0399L36.3424 26.0399L35.2817 27.1005ZM36.1988 28.0177L37.2595 26.957L37.2595 26.957L36.1988 28.0177ZM22.3623 7L22.3623 8.5L22.3623 7ZM34.9293 30.6341H9.79529V33.6341H34.9293V30.6341ZM9.5 30.3388V29.2871H6.5V30.3388H9.5ZM9.58649 29.0783L10.5036 28.1612L8.38224 26.0399L7.46517 26.957L9.58649 29.0783ZM11.2953 26.2499V19.567H8.29529V26.2499H11.2953ZM33.4293 19.567V26.2498H36.4293V19.567H33.4293ZM34.221 28.1612L35.1381 29.0783L37.2595 26.957L36.3424 26.0399L34.221 28.1612ZM35.2246 29.2871V30.3388H38.2246V29.2871H35.2246ZM35.1381 29.0783C35.1935 29.1337 35.2246 29.2088 35.2246 29.2871H38.2246C38.2246 28.4132 37.8774 27.575 37.2595 26.957L35.1381 29.0783ZM33.4293 26.2498C33.4293 26.9667 33.7141 27.6542 34.221 28.1612L36.3424 26.0399C36.398 26.0956 36.4293 26.1711 36.4293 26.2498H33.4293ZM36.4293 19.567C36.4293 11.798 30.1313 5.49998 22.3623 5.5L22.3623 8.5C28.4744 8.49998 33.4293 13.4549 33.4293 19.567H36.4293ZM11.2953 19.567C11.2953 13.4549 16.2501 8.50002 22.3623 8.5L22.3623 5.5C14.5933 5.50002 8.29529 11.798 8.29529 19.567H11.2953ZM10.5036 28.1612C11.0105 27.6543 11.2953 26.9668 11.2953 26.2499H8.29529C8.29529 26.1711 8.32657 26.0956 8.38224 26.0399L10.5036 28.1612ZM9.5 29.2871C9.5 29.2088 9.53111 29.1337 9.58649 29.0783L7.46517 26.957C6.84718 27.575 6.5 28.4132 6.5 29.2871H9.5ZM9.79529 30.6341C9.6322 30.6341 9.5 30.5019 9.5 30.3388H6.5C6.5 32.1587 7.97535 33.6341 9.79529 33.6341V30.6341ZM34.9293 33.6341C36.7493 33.6341 38.2246 32.1587 38.2246 30.3388H35.2246C35.2246 30.5019 35.0924 30.6341 34.9293 30.6341V33.6341Z"
    />
  </svg>`,
})
export class IconNotificationComponent extends IconComponent {}
