import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="user"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconUserComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5 23.5C17.6078 23.5 13.6429 19.583 13.6429 14.75C13.6429 9.91699 17.6078 6 22.5 6C27.3922 6 31.3571 9.91699 31.3571 14.75C31.3571 19.583 27.3922 23.5 22.5 23.5ZM16.3 25.6875H17.4556C18.9917 26.3848 20.7009 26.7812 22.5 26.7812C24.2991 26.7812 26.0013 26.3848 27.5444 25.6875H28.7C33.8344 25.6875 38 29.8027 38 34.875V37.7188C38 39.5303 36.5123 41 34.6786 41H10.3214C8.48772 41 7 39.5303 7 37.7188V34.875C7 29.8027 11.1656 25.6875 16.3 25.6875Z"
    />
  </svg>`,
})
export class IconUserComponent extends IconComponent {}
