@if (label) {
  <label
    class="ui-label ui-label--{{ labelSize }}"
    [ngClass]="{ 'ui-label--required': isRequired }"
    [attr.for]="inputId"
  >
    {{ label }}
  </label>
}
<div class="ui-input__container ui-input__container--with-addons">
  @if (displayCountryDropdown) {
    <div class="ui-input__addon-wrapper">
      <pxw-select-box
        name="countrySelector"
        class="country-selector addon-prepend"
        [options]="countries"
        labelKey="name"
        valueKey="code"
        [ngModel]="countryCode"
        (ngModelChange)="onSelectedCountry($event)"
        fieldTemplate="field"
        [disabled]="disabledCountryCode || isDisabled"
      >
        <ng-template #selectedItemTemplate let-option>
          <div class="country-item">
            @if (option) {
              <span class="selected-country">{{ option.flag }} +{{ option.countryPrefix }}</span>
            }
          </div>
        </ng-template>
        <ng-template #optionItemTemplate let-option>
          <div class="country-item">
            <span class="country-flag">
              {{ option.flag }}
            </span>
            <span class="country-name">
              {{ option.name }}
            </span>
          </div>
        </ng-template>
      </pxw-select-box>
    </div>
  }

  <div class="ui-input__editor">
    <input
      #input
      [id]="inputId"
      [attr.name]="name"
      class="ui-input__input"
      [type]="type"
      [value]="phoneNumber"
      [autofocus]="autofocus"
      [placeholder]="placeholder"
      [attr.pattern]="pattern"
      inputmode="tel"
      [readonly]="isDisabled"
      (keyup)="onKeyUp($event)"
      (focusout)="onFocusOut($event)"
      (paste)="onPaste($event)"
    />
    @if (shouldDisplayErrors) {
      <pxw-icon class="ui-input__error-icon" icon="info" size="sm" />
    }
  </div>

  <div class="ui-input__addon-wrapper">
    <ng-content select=".addon-append"></ng-content>
  </div>
</div>
@if (shouldDisplayErrors) {
  <div class="ui-input__error-wrapper">
    <p class="ui-input__error">{{ getErrorMessage(errorEntries[0]) }}</p>
  </div>
}

@if (helper) {
  <p class="ui-helper">
    {{ helper }}
  </p>
}

<ng-content></ng-content>
