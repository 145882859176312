import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppUserService } from './app-user.service';

@Injectable({providedIn: 'root'})
export class AppAuthStateGuard  {
  private userService = inject(AppUserService);
  private router = inject(Router);
  
  constructor(){}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const sessionExists = await this.userService.checkAndRestoreAuthState();

    if (!sessionExists && !state.url.startsWith('/acceso')) {
      this.userService.setRedirectUrl(state.url);
      this.router.navigate(['/acceso']);

      return false;
    }

    return true;
  }
}