import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

export interface CanDeactivateComponent {
  canDeactivateDirty(): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateDirtyGuard  {
  private t = inject(TranslocoService);
  private readonly location = inject(Location);
  private readonly router = inject(Router);
  
  constructor() {}

  disabled = false;

  canDeactivate(component: CanDeactivateComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {
    const currentUrlTree = this.router.createUrlTree([], currentRoute as any);
    const currentUrl = currentUrlTree.toString();

    if (this.disabled || currentState.url === nextState.url) {
      return false;
    }
    if (typeof component.canDeactivateDirty === 'function' && !component.canDeactivateDirty()) {
      const result = confirm(this.t.translate('global.onDeactivateDirtyMessage'));

      if (result) {
        return true;
      } else {
        // If the deactivation happened because of the browsers' back button, then the current path
        // is temporary different to the current route. Whenever that happens and the user wants to prevent
        // the deactivation, we push a new state with the current route to clean up the forward history
        if (!this.location.path().endsWith(currentUrl)) {
          // The "this.location.go" lines triggers another canDeactivate call, but we don't want to re-evaluate in this case
          this.disabled = true;

          this.location.go(currentUrl);

          setTimeout(() => {
            this.disabled = false;
          }, 50);
        }

        return false;
      }
    }
    return true;
  }
}
