<div class="preview-wrapper preview-type-{{ selectedPreviewType }}">
  <iframe class="preview" name="Viewer" [src]="safeIframeUrl" (load)="onIframeLoaded()"></iframe>
</div>

@if (showControls) {
  <div class="preview-controls">
    <div class="preview-controls-inner">
      <div
        class="preview-btn"
        (click)="selectedPreviewType = 'cellphone'"
        [ngClass]="{ selected: selectedPreviewType === 'cellphone' }"
      >
        <pxw-icon icon="cellphone"></pxw-icon>
      </div>
      <div
        class="preview-btn"
        (click)="selectedPreviewType = 'tablet'"
        [ngClass]="{ selected: selectedPreviewType === 'tablet' }"
      >
        <pxw-icon icon="tablet"></pxw-icon>
      </div>
      <div
        class="preview-btn"
        (click)="selectedPreviewType = 'desktop'"
        [ngClass]="{ selected: selectedPreviewType === 'desktop' }"
      >
        <pxw-icon icon="desktop"></pxw-icon>
      </div>
      <a
        class="preview-btn external-link"
        [href]="externalLinkUrl || safeIframeUrl"
        target="_blank"
      >
        <pxw-icon icon="external-link" color="inherit"></pxw-icon>
      </a>
    </div>
  </div>
}

@if (loading) {
  <div class="ui-loader ui-loader--non-blocking">
    <pxw-icon icon="loading" [spin]="true"></pxw-icon>
  </div>
}

<pxw-icon class="preview-close" icon="close" size="md" (click)="closed.emit()"></pxw-icon>
