<ng-container *transloco="let t">
  @if (isSuperAdmin) {
    <pxw-tag type="success" size="xs">
      <pxw-icon icon="badge" />
      {{ t('invitedUserRole.ESTABLISHMENT_ADMIN') }}
    </pxw-tag>
  } @else {
    <pxw-tooltip [text]="rolesList">
      @if (userRoles.length === 1) {
        {{ t('invitedUsers.userAssignedRoleText') }}
      } @else {
        {{ t('invitedUsers.userAssignedRolesText', { count: userRoles.length }) }}
      }
    </pxw-tooltip>
  }
</ng-container>
