import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="design"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconDesignComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.929 38.9375C16.4279 38.9375 18.5888 38.0453 20.3444 36.2872C22.1869 34.442 23.1451 31.5978 22.8891 28.9032L36.9405 14.8317C37.8997 13.8692 38.4381 12.5646 38.4375 11.2048C38.4369 9.84487 37.8973 8.54084 36.9372 7.57913C35.0044 5.64029 31.631 5.64029 29.6982 7.57913L15.1219 22.1763C11.9831 22.454 8.87556 24.6755 8.87556 28.6156C8.87556 29.1135 8.91002 29.5817 8.94447 30.0287C9.07573 31.7227 9.12331 32.3586 6.53094 33.6566C6.27631 33.7845 6.05929 33.9766 5.90118 34.2139C5.74307 34.4512 5.64932 34.7256 5.62913 35.0102C5.60894 35.2947 5.66301 35.5796 5.78602 35.837C5.90903 36.0943 6.09676 36.3151 6.33077 36.4778C6.47844 36.5764 9.92892 38.9375 13.929 38.9375C13.9274 38.9375 13.9274 38.9375 13.929 38.9375ZM32.0182 9.90082C32.3683 9.56449 32.8349 9.37697 33.32 9.37758C33.8051 9.37819 34.2712 9.56689 34.6205 9.90411C34.7911 10.075 34.9265 10.2779 35.0189 10.5012C35.1112 10.7244 35.1588 10.9638 35.1588 11.2054C35.1588 11.4471 35.1112 11.6864 35.0189 11.9097C34.9265 12.133 34.7911 12.3359 34.6205 12.5068L22.0327 25.1125L19.4305 22.5066L32.0182 9.90082ZM12.2178 29.774C12.1882 29.4059 12.157 29.0215 12.157 28.614C12.157 26.0754 14.5279 25.405 15.7831 25.405C16.3672 25.405 16.93 25.525 17.3648 25.7435C18.9153 26.524 19.4321 27.868 19.5913 28.8588C19.8817 30.6498 19.2352 32.7496 18.0227 33.9639C16.8889 35.0992 15.5501 35.6513 13.929 35.6513H13.9274C12.6969 35.6513 11.5007 35.3293 10.5245 34.9497C12.2998 33.351 12.3638 31.6816 12.2178 29.774Z"
      />
    </svg>
  `,
})
export class IconDesignComponent extends IconComponent {}
