import { isEqual } from 'lodash-es';

export const objectShallowDiffWithDeepCompare = <A extends object, B extends object>(original: A, mutated: B): Partial<B> => {
  return Object.keys(mutated).reduce((diff, key) => {
    if (!isEqual(mutated[key], original[key])) {
      diff[key] = mutated[key];
    }
    return diff;
  }, <Partial<B>>{});
}
