import { bootstrapApplication } from '@angular/platform-browser';
import { appAdminConfig } from './app/app-admin.config';
import { AppAdminComponent } from './app/app-admin.component';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppAdminComponent, appAdminConfig).catch(err => console.error(err));
