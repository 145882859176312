import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="desktop"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconDesktopComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.8182 7H8.18182C6.43182 7 5 8.43182 5 10.1818V29.2727C5 31.0227 6.43182 32.4545 8.18182 32.4545H19.3182V35.6364H16.1364V38.8182H28.8636V35.6364H25.6818V32.4545H36.8182C38.5682 32.4545 40 31.0227 40 29.2727V10.1818C40 8.43182 38.5682 7 36.8182 7ZM36.8182 29.2727H8.18182V10.1818H36.8182V29.2727Z"
      />
    </svg>
  `,
})
export class IconDesktopComponent extends IconComponent {}
