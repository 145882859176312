import { Component, ViewChild, OnDestroy, signal, inject } from '@angular/core';
import { ModalDialogComponent, ModalFormComponent } from '@pedix-workspace/angular-ui-modal';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import {
  IconEyeComponent,
  IconEyeSlashComponent,
  IconFormComponent,
} from '@pedix-workspace/angular-ui-icons';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';
import { noop } from 'lodash-es';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { InputPhoneComponent, InputTextComponent } from '@pedix-workspace/angular-ui-form-reactive';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { ToastrService } from 'ngx-toastr';
import { EstablishmentService } from '../../catalog/establishment.service';
import { ContactInfo, Establishment } from '@pedix-workspace/utils';

@Component({
  selector: 'app-update-contact-info-form',
  templateUrl: './update-contact-info-form.component.html',
  styleUrls: ['./update-contact-info-form.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    AlertComponent,
    FormsModule,
    InputTextComponent,
    IconEyeComponent,
    IconEyeSlashComponent,
    ReactiveFormsModule,
    ButtonComponent,
    IconFormComponent,
    InputPhoneComponent,
    ModalFormComponent,
  ],
})
export class UpdateContactInfoFormComponent implements OnDestroy {
  private formBuilder = inject(FormBuilder);
  private toastrService = inject(ToastrService);
  private t = inject(TranslocoService);
  private establishmentService = inject(EstablishmentService);

  @ViewChild(ModalFormComponent)
  modalForm: ModalFormComponent;

  form: FormGroup<{
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    email: FormControl<string>;
    phone: FormControl<string>;
  }>;

  #updatingContactInfo = signal(false);

  get updatingContactInfo() {
    return this.#updatingContactInfo();
  }
  get establshment(): Establishment {
    return this.establishmentService.currentEstablishment;
  }
  get contactInfo(): ContactInfo {
    return this.establshment.contactInfo;
  }

  matchingPasswordsValidator: ValidatorFn = (control: AbstractControl) => {
    const passwordControl = control.get('password');
    const repeatPasswordControl = control.get('passwordRepeat');

    if (passwordControl!.errors && !repeatPasswordControl!.errors?.['passwordMatch']) {
      return null;
    }

    if (passwordControl.value !== repeatPasswordControl.value) {
      const error = { passwordMatch: true };

      repeatPasswordControl.setErrors(error);
    } else {
      repeatPasswordControl.setErrors(null);
      return null;
    }
  };

  ngOnDestroy(): void {
    this.modalForm?.close();
  }

  open() {
    this.#updatingContactInfo.set(false);

    this.form = this.formBuilder.group({
      firstName: new FormControl(this.contactInfo.firstName, [Validators.required]),
      lastName: new FormControl(this.contactInfo.lastName, [Validators.required]),
      email: new FormControl(this.contactInfo.email, [Validators.required, Validators.email]),
      phone: new FormControl(this.contactInfo.phone, [Validators.required]),
    });

    this.modalForm.open();
  }

  close() {
    this.modalForm.close();
  }

  async onUpdateContactInfo() {
    if (!this.form.valid) {
      return;
    }
    const updatedContactInfo = Object.assign({}, this.contactInfo, this.form.value);

    this.form.disable();

    this.#updatingContactInfo.set(true);

    try {
      await this.establishmentService.update(this.establshment.id, {
        contactInfo: updatedContactInfo,
      });

      this.toastrService.success(this.t.translate('updateContactInfoDialog.updateSuccess'));

      this.modalForm.close();
    } catch (error) {
      console.error(error);

      this.toastrService.error(this.t.translate('error.retryLater'));
    }

    this.#updatingContactInfo.set(false);

    this.form.enable();
  }
}
