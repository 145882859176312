export type TranslateParams = {
  value: string;
  fromLanguage: string;
  toLanguage: string;
};

export type TranslationResult = {
  data: {
    translations: { translatedText: string }[];
  };
};

const googleTranslateEndpoint = 'https://translation.googleapis.com/language/translate/v2';

export async function googleTranslateText(
  apiKey: string,
  { value, fromLanguage, toLanguage }: TranslateParams,
): Promise<string> {
  const searchParams = new URLSearchParams({
    q: value,
    source: fromLanguage,
    target: toLanguage,
    key: apiKey,
  });
  const translateRequest = `${googleTranslateEndpoint}?${searchParams.toString()}`;

  const result: TranslationResult = await fetch(translateRequest, {
    method: 'POST',
  }).then(res => res.json());

  return result.data.translations[0].translatedText;
}
