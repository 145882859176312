import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="statistics"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconStatisticsComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 8V34.6667C7.5 35.5507 7.85119 36.3986 8.47631 37.0237C9.10143 37.6488 9.94928 38 10.8333 38H37.5"
      stroke="#FF7C7C"
      stroke-width="3"
      stroke-miterlimit="5.759"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0625 26.75L20.7589 19.875L27.4554 26.75L37.5 16.4375"
      stroke="#FF7C7C"
      stroke-width="3"
      stroke-miterlimit="5.759"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.8125 16.4375H37.5V21.125"
      stroke="#FF7C7C"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>`,
})
export class IconStatisticsComponent extends IconComponent {}
