import { Pipe, PipeTransform } from '@angular/core';
import { Product, ProductOptionItem } from '@pedix-workspace/utils';

type WithSortingProps<T> = T &
  Pick<Product, 'name' | 'sku'> & { presentations?: { items: ProductOptionItem[] } };

@Pipe({
  name: 'pxwFilterProductsBySearchTerm',
  standalone: true,
})
export class FilterProductsBySearchTermPipe<T> implements PipeTransform {
  transform(products: WithSortingProps<T>[], searchTerm = '', displaySku = false): any {
    if (!searchTerm) {
      return products;
    }
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const uppercasedSearchTerm = searchTerm.toUpperCase();

    return products.filter(product => {
      if (displaySku) {
        // SKU is always uppercase
        if (product.presentations?.items?.find(item => item.sku?.includes(uppercasedSearchTerm))) {
          return true;
        }
        if (product.sku?.includes(uppercasedSearchTerm)) {
          return true;
        }
      }
      return product.name.toLowerCase().includes(lowercasedSearchTerm);
    });
  }
}
