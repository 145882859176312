/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash-es';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pxwSelectBoxOptionKey',
  standalone: true,
})
export class SelectBoxOptionKeyPipe implements PipeTransform {
  transform(option: any, key: string | undefined): any {
    return key ? get(option, key) : option;
  }
}
