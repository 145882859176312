export const getStorageFilePath = (fileUrl: string, bucketName: string): string => {
  // e.g.: https://storage.googleapis.com/comprarporwhatsapp-develop.appspot.com/DZwue3Pa21YQo3e4Z9Dx/products/1596836917568.png?GoogleAccessId=comprarporwhatsapp-develop%40appspot.gserviceaccount.com&Expires=13569465600&...
  if (fileUrl.startsWith('https://storage.googleapis.com')) {
    const [fileUrlWithoutQuery] = fileUrl.split('?');
    const bucketPrefix = `${bucketName}/`;
    const urlPathOffset = fileUrlWithoutQuery.indexOf(bucketPrefix) + bucketPrefix.length;

    return fileUrlWithoutQuery.substring(urlPathOffset);
  } else {
    // e.g.: https://firebasestorage.googleapis.com/v0/b/comprarporwhatsapp-19eb8.appspot.com/o/MWfL7v9QDlts7y2E1jUw%2Fproducts%2F1591821250759.png?alt=media&token=843b5816-143b-4ec8-a6e1-f622988c04a2
    const [fileUrlWithoutQuery] = fileUrl.split('?');
    const bucketPrefix = `${bucketName}/o/`;
    const urlPathOffset = fileUrlWithoutQuery.indexOf(bucketPrefix) + bucketPrefix.length;

    return decodeURIComponent(fileUrlWithoutQuery.substring(urlPathOffset));
  }
}

export const replaceFileNameInPath = (filePath: string, newFileName: string): string => {
  const pathParts = filePath.split('/');
  const lastPart = pathParts.pop();
  const [, extension] = lastPart.split('.');

  return `${pathParts.join('/')}/${newFileName}.${extension}`;
}
