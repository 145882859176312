import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="growth"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconGrowthComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.0893 18.6641C36.474 18.6624 37.8016 18.1186 38.7807 17.1519C39.7599 16.1853 40.3108 14.8748 40.3125 13.5078V9.64062H36.3951C35.0581 9.64257 33.7734 10.1532 32.808 11.0663C32.1316 9.85464 31.1385 8.84411 29.9321 8.13995C28.7257 7.43578 27.3502 7.06372 25.9487 7.0625H22.0312V10.9297C22.0337 12.9802 22.8599 14.9461 24.3287 16.3961C25.7975 17.846 27.7889 18.6617 29.8661 18.6641H31.1719V35.4219H15.5022V28.9766H16.808C18.1928 28.9749 19.5203 28.4311 20.4995 27.4644C21.4787 26.4978 22.0295 25.1873 22.0312 23.8203V19.9531H18.1138C16.7769 19.9551 15.4921 20.4657 14.5268 21.3788C13.8503 20.1671 12.8572 19.1566 11.6508 18.4524C10.4444 17.7483 9.0689 17.3762 7.66741 17.375H3.75V21.2422C3.75242 23.2927 4.57865 25.2586 6.04744 26.7086C7.51623 28.1585 9.50764 28.9742 11.5848 28.9766H12.8906V35.4219H3.75V38H40.3125V35.4219H33.7835V18.6641H35.0893ZM33.7835 14.7969C33.7842 14.1133 34.0595 13.458 34.5492 12.9746C35.0388 12.4913 35.7027 12.2194 36.3951 12.2188H37.7009V13.5078C37.7002 14.1914 37.4248 14.8467 36.9352 15.3301C36.4456 15.8134 35.7817 16.0853 35.0893 16.0859H33.7835V14.7969ZM15.5022 25.1094C15.5029 24.4258 15.7783 23.7705 16.2679 23.2871C16.7575 22.8038 17.4214 22.5319 18.1138 22.5312H19.4196V23.8203C19.419 24.5039 19.1436 25.1592 18.654 25.6426C18.1643 26.1259 17.5005 26.3978 16.808 26.3984H15.5022V25.1094ZM12.8906 26.3984H11.5848C10.2001 26.3967 8.87253 25.8529 7.89336 24.8863C6.91419 23.9197 6.36333 22.6092 6.36161 21.2422V19.9531H7.66741C9.05216 19.9548 10.3797 20.4986 11.3589 21.4652C12.338 22.4319 12.8889 23.7424 12.8906 25.1094V26.3984ZM31.1719 16.0859H29.8661C28.4813 16.0842 27.1538 15.5404 26.1746 14.5738C25.1954 13.6072 24.6446 12.2967 24.6429 10.9297V9.64062H25.9487C27.3334 9.64233 28.661 10.1861 29.6401 11.1527C30.6193 12.1194 31.1701 13.4299 31.1719 14.7969V16.0859Z"
    />
  </svg>`,
})
export class IconGrowthComponent extends IconComponent {}
