import { Injectable } from '@angular/core';

export type TranslateTextParams = {
  value: string;
  fromLanguage: string;
  toLanguage: string;
};

@Injectable({
  providedIn: 'root',
})
export class AngularUiFormConfig {
  getCurrencySymbol(): string {
    return '$';
  }

  getValidationRequiredText(): string {
    return 'Required';
  }

  getMaskInvalidFormatText(): string {
    return 'Invalid format';
  }

  getUseInputDecimals(): boolean {
    return true;
  }

  getRequiredLanguagesText(languages: string[]): string {
    return `Missing translations: ${languages.join(', ')}`;
  }

  async translateText({ value, fromLanguage, toLanguage }: TranslateTextParams): Promise<string> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(`${value}: ${fromLanguage}->${toLanguage}`);
      }, 500);
    });
  }
}
