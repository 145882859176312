import { effect, Injectable, signal } from '@angular/core';

export type AppAdminSessionState = {
  selectedEstablishment?: string;
};

export const ADMIN_SESSION_KEY = 'PEDIX-ADMIN';

@Injectable({ providedIn: 'root' })
export class AppAdminSessionService {
  #state = signal<AppAdminSessionState>({});

  get selectedEstablishment() {
    return this.#state().selectedEstablishment;
  }
  set selectedEstablishment(selectedEstablishment: string) {
    this.#state.update(state => Object.assign({}, state, { selectedEstablishment }));
  }

  constructor() {
    if (sessionStorage.getItem(ADMIN_SESSION_KEY)) {
      this.#state.set(JSON.parse(sessionStorage.getItem(ADMIN_SESSION_KEY)));
    }

    effect(() => {
      sessionStorage.setItem(ADMIN_SESSION_KEY, JSON.stringify(this.#state()));
    });
  }

  clear() {
    this.#state.set({});
  }
}
