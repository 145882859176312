import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pxwValueInSet',
  standalone: true,
})
export class ValueInSetPipe<T> implements PipeTransform {
  transform(value: T, set: Set<T>): boolean {
    return set.has(value);
  }
}
