import { inject } from '@angular/core';
import { Injectable } from "@angular/core";
import { CurrencyLocaleData } from "@pedix-workspace/utils";
import { CurrencyFormatService } from "@pedix-workspace/pedixapp-shared-pipes";
import { EstablishmentService } from "./features/catalog/establishment.service";

@Injectable()
export class AppCurrencyFormatService extends CurrencyFormatService {

  private establishmentService = inject(EstablishmentService);
  
  constructor() {
    super();
  }

  get currencyLocaleData(): CurrencyLocaleData {
    return this.establishmentService.currencyLocaleData;
  }
}
