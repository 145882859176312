import {
  Category,
  DEFAULT_AVAILABILITY,
  Feature,
  SUPPORTED_LANGUAGES,
  firestoreDateParser,
  getPropInSelectedLanguage,
} from '@pedix-workspace/utils';
import { FirestoreNestedConverter, withNestedConverter } from './utils';

export type CategoryConverterParams = {
  nestedConverter?: FirestoreNestedConverter<Category>;
  language?: SUPPORTED_LANGUAGES;
  features?: Feature[];
};

export const getCategoryConverter = ({
  language,
  features,
  nestedConverter,
}: CategoryConverterParams = {}) => {
  const featuresSet = features ? new Set(features) : undefined;

  return withNestedConverter({
    fromFirestore(snapshot) {
      const categoryData = <Category>snapshot.data();
      const name = getPropInSelectedLanguage(categoryData, 'name', language, true);
      const isDynamic = categoryData.isDynamic ?? false;
      const linkedProductTagIds = categoryData.linkedProductTagIds || [];
      const availability = categoryData.availability || DEFAULT_AVAILABILITY;
      const dynamicGrouping = isDynamic ? categoryData.dynamicGrouping || 'category' : undefined;

      if (availability && availability.isHidden === undefined) {
        availability.isHidden = false;
      }

      const convertedData = <Category>{
        ...categoryData,
        id: snapshot.id,
        name: name?.trim(),
        active: categoryData.active === undefined ? true : categoryData.active,
        hideLabelInList:
          categoryData.hideLabelInList === undefined ? false : categoryData.hideLabelInList,
        availability,
        isDynamic,
        dynamicGrouping,
        linkedProductTagIds,
        added: firestoreDateParser(categoryData.added),
        updated: firestoreDateParser(categoryData.updated),
      };

      // If provided with available features, performs data validation/truncation
      if (featuresSet && featuresSet.size > 0) {
        if (!featuresSet.has('CATEGORY_AVAILABILITY')) {
          convertedData.availability = DEFAULT_AVAILABILITY;
        }
        if (!featuresSet.has('DYNAMIC_CATEGORIES')) {
          convertedData.linkedProductTagIds = [];

          delete convertedData.productsOrder;
        }
      }

      return convertedData;
    },
    toFirestore(category: Category): Category {
      return category;
    },
  })(nestedConverter);
};
