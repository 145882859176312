import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="catalog"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconCatalogComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9062 34.25H29.5312V39.875H23.9062V34.25ZM33.75 34.25H39.375V39.875H33.75V34.25ZM23.9062 24.4062H29.5312V30.0312H23.9062V24.4062ZM33.75 24.4062H39.375V30.0312H33.75V24.4062Z"
      />
      <path
        d="M39.375 15.9688H30.9375V10.3438C30.9375 7.95312 29.1094 6.125 26.7188 6.125H18.2812C15.8906 6.125 14.0625 7.95312 14.0625 10.3438V15.9688H5.625C4.78125 15.9688 4.21875 16.5312 4.21875 17.375V17.6562L6.89063 34.6719C7.03125 36.0781 8.29688 37.0625 9.70312 37.0625H21.0938V34.25H9.70312L7.3125 18.7812H39.375V15.9688ZM16.875 10.3438C16.875 9.5 17.4375 8.9375 18.2812 8.9375H26.7188C27.5625 8.9375 28.125 9.5 28.125 10.3438V15.9688H16.875V10.3438Z"
      />
    </svg>
  `,
})
export class IconCatalogComponent extends IconComponent {}
