import { Injectable, inject, signal } from '@angular/core';
import {
  Auth,
  authState,
  signInWithCustomToken,
  updateCurrentUser,
  updatePassword,
  User,
} from '@angular/fire/auth';
import { skip } from 'rxjs/operators';
import {
  InvitedUserRole,
  PasswordRecoveryRequest,
  UserDataWithClaims,
} from '@pedix-workspace/utils';
import { HttpClient } from '@angular/common/http';
import { LoginService } from './features/auth/login/login.service';
import { environment } from '../environments/environment';
import { AppAdminSessionService } from './features/app-admin-session.service';
import { EstablishmentService } from './features/catalog/establishment.service';

@Injectable({
  providedIn: 'root',
})
export class AppUserService {
  private auth = inject(Auth);
  private httpClient = inject(HttpClient);
  private loginService = inject(LoginService);
  private adminSessionService = inject(AppAdminSessionService);
  private establishmentService = inject(EstablishmentService);

  private redirectUrl: string | null = null;

  #user = signal<User | null>(null);
  #userWithClaims = signal<UserDataWithClaims | null>(null);

  get user(): User | null {
    return this.#user();
  }
  get userWithClaims(): UserDataWithClaims | null {
    return this.#userWithClaims();
  }
  get isSuperAdmin() {
    return this.isEstablishmentUser && Boolean(this.userWithClaims?.claims?.appSuperAdmin);
  }
  get isEstablishmentUser() {
    return this.user?.email.endsWith(`@${environment.emailDomain}`);
  }
  get isInvitedUser() {
    return Boolean(this.userWithClaims?.claims?.appInvitedUser);
  }
  get userRoles(): InvitedUserRole[] {
    if (this.isEstablishmentUser) {
      return ['ESTABLISHMENT_ADMIN'];
    }
    return this.establishmentService.currentEstablishment?.userIds[this.user.uid] || [];
  }

  constructor() {
    // Listen for authentication updates
    authState(this.auth)
      .pipe(skip(1))
      .subscribe((user: User | null) => {
        this.setUserStateFromFirebase(user);
      });
  }

  async checkAndRestoreAuthState(): Promise<boolean> {
    try {
      const { customToken } = await this.httpClient
        .post<{ customToken?: string }>(
          '/auth/state',
          {},
          {
            withCredentials: true,
          },
        )
        .toPromise();

      if (!customToken) {
        return false;
      }

      const userCredential = await signInWithCustomToken(this.auth, customToken);

      const idTokenResult = await this.setUserStateFromFirebase(userCredential.user);

      if (idTokenResult) {
        // NOTE: we call this to refresh session (which has fixed expiration and cannot be automatically renewed)
        // It is not being awaited on purpuse
        // This means that as long as the user uses the system while the token is still valid, it's expiration time
        // will be automatically extended (for the given time configured in the backend service)
        this.loginService.setOrRefreshUserSession(idTokenResult.token);
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  async getLoggedUserPromise(): Promise<User | null> {
    return Promise.resolve(this.user);
  }

  async createPasswordRecoveryRequest(
    params: Pick<PasswordRecoveryRequest, 'establishmentSlug' | 'registrationEmail'>,
  ) {
    await this.httpClient
      .post(`${environment.apiUrl}/pedix/password-recovery-request`, params)
      .toPromise();
  }

  async processPasswordRecoveryRequest(
    passwordRecoveryRequestId: string,
    establishmentSlug: string,
    newPassword: string,
  ) {
    await this.httpClient
      .post(`${environment.apiUrl}/pedix/process-password-recovery-request`, {
        passwordRecoveryRequestId,
        establishmentSlug,
        newPassword,
      })
      .toPromise();
  }

  async updatePassword(newPassword: string) {
    await updatePassword(this.user, newPassword);

    await this.updateCurrentUser(this.user);
  }

  async getObfuscatedEstablishmentEmail(establishmentSlug: string): Promise<string> {
    const response = await this.httpClient
      .get<{
        obfuscatedEmail: string;
      }>(
        `${environment.apiUrl}/pedix/obfuscated-establishment-email?establishmentSlug=${establishmentSlug}`,
      )
      .toPromise();

    return response.obfuscatedEmail;
  }

  async updateCurrentUser(user: User) {
    await updateCurrentUser(this.auth, user);

    const idTokenResult = await user.getIdTokenResult();

    await this.loginService.setOrRefreshUserSession(idTokenResult.token);
  }

  private async setUserStateFromFirebase(user: User | null) {
    if (user) {
      if (this.user && this.user.uid !== user.uid) {
        this.adminSessionService.clear();
        this.establishmentService.clearCurrentEstablishmentSubscription();
      }
      const idTokenResult = await user.getIdTokenResult();

      this.#user.set(user);
      this.#userWithClaims.set({
        uid: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL,
        email: user.email,
        claims: {
          appSuperAdmin: Boolean(idTokenResult.claims['appSuperAdmin']),
          appInvitedUser: Boolean(idTokenResult.claims['appInvitedUser']),
        },
      });
      return idTokenResult;
    } else {
      this.adminSessionService.clear();
      this.establishmentService.clearCurrentEstablishmentSubscription();

      this.#user.set(null);
      this.#userWithClaims.set(null);

      return null;
    }
  }
  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }

  clearRedirectUrl(): void {
    this.redirectUrl = null;
  }
}
