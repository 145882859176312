import { getStorageFilePath, replaceFileNameInPath } from './getStorageFilePath';
import { ImageToClone } from "./types";

export const getCloneField = (field: string) => `${field}ToClone`;

export const generateRandomFileName = () => `${Date.now()}-${Math.floor(Math.random() * 99999)}`;

export const getImageToClone = (imageUrl: string, bucketName: string, newFileName: string): ImageToClone => {
  const srcPath = getStorageFilePath(imageUrl, bucketName);
  const destPath = replaceFileNameInPath(srcPath, newFileName);
  
  return {
    srcPath,
    destPath,
  };
};
