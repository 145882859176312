import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pxwSelectBoxOptionFilter',
  standalone: true,
})
export class SelectBoxOptionFilterPipe implements PipeTransform {
  transform(options: any[], searchText?: string, valueKey?: string): any[] {
    const sanitizedSearchText = (searchText || '').trim().toLowerCase();

    if (!sanitizedSearchText) {
      return options;
    }
    return options.filter(option => {
      return (<string>(valueKey ? option[valueKey] : option))
        .toLowerCase()
        .includes(sanitizedSearchText.toLowerCase());
    });
  }
}
