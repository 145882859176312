import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';

import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { TagComponent } from '@pedix-workspace/angular-ui-alert';
import { IconBadgeComponent } from '@pedix-workspace/angular-ui-icons';
import { TooltipComponent } from '@pedix-workspace/angular-ui-tooltip';
import { InvitedUserRole } from '@pedix-workspace/utils';

@Component({
  selector: 'app-user-invite-roles',
  templateUrl: './user-invite-roles.component.html',
  styleUrls: ['./user-invite-roles.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoDirective, TagComponent, TooltipComponent, IconBadgeComponent],
})
export class UserInviteRolesComponent {
  private t = inject(TranslocoService);

  @Input() userRoles: InvitedUserRole[] = [];

  get isSuperAdmin() {
    return this.userRoles[0] === 'ESTABLISHMENT_ADMIN';
  }

  get rolesList() {
    return this.userRoles.map(role => this.t.translate(`invitedUserRole.${role}`)).join(', ');
  }
}
