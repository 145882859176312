import {
  Component,
  ChangeDetectionStrategy,
  Input,
  AfterContentInit,
  ContentChild,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@pedix-workspace/angular-ui-icons';

@Component({
  selector: 'pxw-section-menu',
  templateUrl: './section-menu.component.html',
  styleUrls: ['./section-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, IconComponent],
})
export class SectionMenuComponent implements AfterContentInit {
  @ContentChild(IconComponent) icon: IconComponent;

  @Input() title = '';
  @Input() description = '';
  @Input() routerLink: RouterLink['routerLink'];

  ngAfterContentInit(): void {
    if (this.icon) {
      this.icon.size = 'md';
      this.icon.color = 'pink';
    }
  }
}
