import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="cellphone"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconCellphoneComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.7045 5H15.9773C14.9224 5 13.9108 5.41903 13.1649 6.16492C12.419 6.9108 12 7.92243 12 8.97727V36.0227C12 37.0776 12.419 38.0892 13.1649 38.8351C13.9108 39.581 14.9224 40 15.9773 40H28.7045C29.7594 40 30.771 39.581 31.5169 38.8351C32.2628 38.0892 32.6818 37.0776 32.6818 36.0227V8.97727C32.6818 7.92243 32.2628 6.9108 31.5169 6.16492C30.771 5.41903 29.7594 5 28.7045 5ZM22.3409 38.4091C21.0205 38.4091 19.9545 37.3432 19.9545 36.0227C19.9545 34.7023 21.0205 33.6364 22.3409 33.6364C23.6614 33.6364 24.7273 34.7023 24.7273 36.0227C24.7273 37.3432 23.6614 38.4091 22.3409 38.4091ZM29.5 32.0455H15.1818V9.77273H29.5V32.0455Z"
      />
    </svg>
  `,
})
export class IconCellphoneComponent extends IconComponent {}
