export const COUNTRY_CODE_OTHER = 'XX';

export const COUNTRY_LIST = [
  {
    code: 'AR',
    flag: '🇦🇷',
    name: 'Argentina',
    countryPrefix: '54',
    flagImage: 'http://cdn.pedix.app/landing/flags/argentina.png',
  },
  {
    code: 'BR',
    flag: '🇧🇷',
    name: 'Brasil',
    countryPrefix: '55',
    flagImage: 'http://cdn.pedix.app/landing/flags/brasil.png',
  },
  {
    code: 'CL',
    flag: '🇨🇱',
    name: 'Chile',
    countryPrefix: '56',
    flagImage: 'http://cdn.pedix.app/landing/flags/chile.png',
  },
  {
    code: 'CO',
    flag: '🇨🇴',
    name: 'Colombia',
    countryPrefix: '57',
    flagImage: 'http://cdn.pedix.app/landing/flags/colombia.png',
  },
  {
    code: 'MX',
    flag: '🇲🇽',
    name: 'México',
    countryPrefix: '52',
    flagImage: 'http://cdn.pedix.app/landing/flags/mexico.png',
  },
  {
    code: 'PE',
    flag: '🇵🇪',
    name: 'Perú',
    countryPrefix: '51',
    flagImage: 'http://cdn.pedix.app/landing/flags/peru.png',
  },
  {
    code: 'UY',
    flag: '🇺🇾',
    name: 'Uruguay',
    countryPrefix: '598',
    flagImage: 'http://cdn.pedix.app/landing/flags/uruguay.png',
  },
  {
    code: 'PY',
    flag: '🇵🇾',
    name: 'Paraguay',
    countryPrefix: '595',
    flagImage: 'http://cdn.pedix.app/landing/flags/paraguaydark.png',
  },
  {
    code: COUNTRY_CODE_OTHER,
    flag: '🏳',
    name: 'Otro',
    countryPrefix: '',
    flagImage: 'http://cdn.pedix.app/landing/flags/otro.png',
  },
];
