<ng-container *transloco="let t">
  <pxw-modal-form
    #aceptAdminInviteDialog
    [title]="t('updateContactInfoDialog.title')"
    type="modal"
    modalStyle="small"
  >
    <ng-template #modalContent>
      <pxw-alert style="margin-bottom: 2em" type="info" size="sm">{{
        t('updateContactInfoDialog.intro')
      }}</pxw-alert>
      <form [formGroup]="form">
        <pxw-input-text
          name="firstName"
          formControlName="firstName"
          [label]="t('updateContactInfoDialog.firstNameLabel')"
          [displayErrors]="true"
        />
        <pxw-input-text
          name="lastName"
          formControlName="lastName"
          [label]="t('updateContactInfoDialog.lastNameLabel')"
          [displayErrors]="true"
        />
        <pxw-input-text
          name="email"
          formControlName="email"
          [inputMode]="'email'"
          [label]="t('updateContactInfoDialog.emailLabel')"
          [displayErrors]="true"
        />
        <pxw-input-phone
          name="phone"
          formControlName="phone"
          [label]="t('updateContactInfoDialog.phoneLabel')"
          [displayErrors]="true"
          [messages]="{
            invalidPhone: t('validation.invalidPhone')
          }"
        />
      </form>
    </ng-template>
    <ng-template #modalFooter>
      <pxw-button
        size="md"
        color="blue"
        [rounded]="true"
        [disabled]="!form.valid"
        [appareance]="'outline'"
        (click)="close()"
      >
        {{ t('global.cancel') }}
      </pxw-button>
      <pxw-button
        mode="submit"
        size="md"
        color="blue"
        [rounded]="true"
        [loading]="updatingContactInfo"
        [disabled]="!form.valid"
        (click)="onUpdateContactInfo()"
      >
        {{ t('updateContactInfoDialog.updateButton') }}
      </pxw-button>
    </ng-template>
  </pxw-modal-form>
</ng-container>
