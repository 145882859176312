import {
  Component,
  ChangeDetectionStrategy,
  ContentChild,
  TemplateRef,
  ElementRef,
  OnInit,
  ChangeDetectorRef,
  Input,
  OnDestroy,
  HostBinding,
  inject
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, of, timer } from 'rxjs';
import { debounce, distinctUntilChanged, filter } from 'rxjs/operators';
import clsx from 'clsx';
import { NgTemplateOutlet } from '@angular/common';
import { DxTemplateModule } from 'devextreme-angular/core';
import { DxoPositionModule } from 'devextreme-angular/ui/nested';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';

@UntilDestroy()
@Component({
  selector: 'pxw-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DxTooltipModule, DxoPositionModule, DxTemplateModule, NgTemplateOutlet],
})
export class TooltipComponent implements OnInit, OnDestroy {
  @ContentChild('tooltipContent') tooltipContent: TemplateRef<any>;

  @Input() text: string;
  @Input() openDelay = 1000;
  @Input() closeDelay = 200;
  @Input() offsetY = 10;
  @Input() disabled = false;

  visible = true;
  ready = false;
  tooltipVisible$ = new BehaviorSubject<boolean>(false);

  private skipDebounce = false;

  get target() {
    return this.elRef.nativeElement;
  }

  @HostBinding('class')
  get classNames() {
    return clsx('ui-tooltip', {
      'ui-tooltip--disabled': this.disabled,
      ready: this.ready,
    });
  }

  private elRef = inject(ElementRef);
  private cd = inject(ChangeDetectorRef);
  
  constructor() {}

  ngOnInit(): void {
    this.elRef.nativeElement.addEventListener('click', this.onMouseClickDisplay);
    this.elRef.nativeElement.addEventListener('mouseenter', this.onMouseEnter);
    this.elRef.nativeElement.addEventListener('mouseleave', this.onMouseLeave);

    this.tooltipVisible$
      .pipe(
        filter(visible => (visible && this.disabled ? false : true)),
        debounce(value => {
          if (this.skipDebounce) {
            this.skipDebounce = false;

            return of(value);
          }
          return timer(value === true ? this.openDelay : this.closeDelay);
        }),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(visible => {
        this.visible = visible;
        this.ready = true;

        this.cd.markForCheck();
      });
  }

  ngOnDestroy() {
    this.elRef.nativeElement.removeEventListener('click', this.onMouseClickDisplay);
    this.elRef.nativeElement.removeEventListener('mouseenter', this.onMouseEnter);
    this.elRef.nativeElement.removeEventListener('mouseleave', this.onMouseLeave);
  }

  onShowingTooltip({ component }: { component: any }) {
    component.content().addEventListener('click', this.onMouseClickHide);
    component.content().addEventListener('mouseenter', this.onMouseEnter);
    component.content().addEventListener('mouseleave', this.onMouseLeave);
  }

  onHiddenTooltip({ component }: { component: any }) {
    component.content().removeEventListener('click', this.onMouseClickHide);
    component.content().removeEventListener('mouseenter', this.onMouseEnter);
    component.content().removeEventListener('mouseleave', this.onMouseLeave);
  }

  onMouseClickDisplay = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();

    this.skipDebounce = true;
    this.tooltipVisible$.next(true);
  };

  onMouseClickHide = () => {
    this.skipDebounce = true;
    this.tooltipVisible$.next(false);
  };

  onMouseEnter = () => {
    this.tooltipVisible$.next(true);
  };

  onMouseLeave = () => {
    this.tooltipVisible$.next(false);
  };
}
