import {
  Component,
  ChangeDetectionStrategy,
  Input,
  inject,
  signal,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { Establishment } from '@pedix-workspace/utils';
import { TranslocoDirective } from '@ngneat/transloco';
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
} from '@ng-bootstrap/ng-bootstrap';
import { NgClass } from '@angular/common';
import { TagComponent } from '@pedix-workspace/angular-ui-alert';
import { UserInviteRolesComponent } from '../../users/user-invite-roles/user-invite-roles.component';
import { EstablishmentSelectorInputComponent } from '../establishment-selector-input/establishment-selector-input.component';
import { AppAdminSessionService } from '../../app-admin-session.service';
import { Router } from '@angular/router';
import { LoginService } from '../../auth/login/login.service';
import {
  IconChangePasswordComponent,
  IconFormComponent,
  IconSignOutComponent,
  IconUserComponent,
} from '@pedix-workspace/angular-ui-icons';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { Options } from '@popperjs/core';
import { AppUserService } from '../../../app-user.service';
import { UpdateContactInfoFormComponent } from '../update-contact-info-form/update-contact-info-form.component';

@UntilDestroy()
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownItem,
    NgbDropdownToggle,
    NgClass,
    TagComponent,
    UserInviteRolesComponent,
    EstablishmentSelectorInputComponent,
    IconSignOutComponent,
    IconUserComponent,
    ChangePasswordDialogComponent,
    UpdateContactInfoFormComponent,
    IconUserComponent,
    IconChangePasswordComponent,
    IconFormComponent,
  ],
})
export class UserMenuComponent {
  private adminSessionService = inject(AppAdminSessionService);
  private router = inject(Router);
  private loginService = inject(LoginService);
  private userService = inject(AppUserService);

  @ViewChild(NgbDropdown)
  private dropdown: NgbDropdown;

  @ViewChild(ChangePasswordDialogComponent)
  private changePasswordDialog: ChangePasswordDialogComponent;

  @ViewChild(UpdateContactInfoFormComponent)
  private updateContactInfoForm: UpdateContactInfoFormComponent;

  @Input() establishment: Establishment;
  @Input() invitedUserEstablishments: Establishment[] = [];

  #loadingRedirect = signal(false);

  popperOptions = (options: Partial<Options>): Partial<Options> => ({
    ...options,
    modifiers: [
      ...options.modifiers,
      {
        name: 'offset',
        options: {
          offset: [80, 10],
        },
      },
    ],
  });

  get user() {
    return this.userService.user;
  }
  get userRoles() {
    return this.userService.userRoles;
  }
  get isEstablishmentUser() {
    return this.userService.isEstablishmentUser;
  }
  get isInvitedUser() {
    return this.userService.isInvitedUser;
  }
  get loadingRedirect() {
    return this.#loadingRedirect();
  }
  get userInitials() {
    const displayNameParts = this.user.displayName?.split(' ') || [];
    const initials = [];

    if (displayNameParts[0]) {
      initials.push(displayNameParts[0][0]);
    }
    if (
      displayNameParts[displayNameParts.length - 1] &&
      displayNameParts[displayNameParts.length - 1] !== displayNameParts[0]
    ) {
      initials.push(displayNameParts[displayNameParts.length - 1][0]);
    }

    return initials.join('').toUpperCase();
  }

  async onChangePassword() {
    this.changePasswordDialog.open();
  }

  onUpdateContactInfo() {
    this.updateContactInfoForm.open();
  }

  onEstablishmentSelected(establishment: Establishment) {
    if (this.adminSessionService.selectedEstablishment === establishment.slug) {
      this.dropdown.close();
      return;
    }
    this.adminSessionService.selectedEstablishment = establishment.slug;

    this.#loadingRedirect.set(true);

    location.href = this.router.createUrlTree(['/inicio']).toString();
  }

  logout() {
    this.loginService.logout();

    if (this.isInvitedUser) {
      this.router.navigate(['/acceso/invitados'], {
        queryParams: {
          email: this.userService.user.email,
        },
      });
    } else {
      this.router.navigate(['/acceso'], {
        queryParams: {
          username: this.establishment.slug,
        },
      });
    }
  }
}
