import { Injectable } from '@angular/core';
import { Category } from '@pedix-workspace/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CategoriesStoreService {
  #categoriesCache$ = new BehaviorSubject<Map<string, Category | undefined>>(new Map());
  #establishmentsCached: Set<string> = new Set();
  #categoriesCached: Set<string> = new Set();

  addCategoryToCache(categoryId: string, category: Category | undefined): void {
    const categoriesCache = this.#categoriesCache$.value;

    categoriesCache.set(categoryId, category);

    this.#categoriesCached.add(categoryId);

    this.#categoriesCache$.next(categoriesCache);
  }

  addCategoriesToCache(
    establishmentId: string,
    categories: Category[],
    options: { updateCache: boolean },
  ): void {
    if (options.updateCache) {
      this.#establishmentsCached.add(establishmentId);
    }

    const categoriesCache = this.#categoriesCache$.value;

    categories.forEach(category => {
      categoriesCache.set(category.id, category);

      if (options.updateCache) {
        this.#categoriesCached.add(category.id);
      }
    });

    this.#categoriesCache$.next(categoriesCache);
  }

  patchCategoryInCache(categoryId: string, dataPatch: Partial<Category>): void {
    const categoriesCache = this.#categoriesCache$.value;

    const updatedCategory = <Category>{
      ...categoriesCache.get(categoryId),
      ...dataPatch,
    };
    categoriesCache.set(categoryId, updatedCategory);

    this.#categoriesCache$.next(categoriesCache);
  }

  removeCategoryFromCache(categoryId: string): void {
    const categoriesCache = this.#categoriesCache$.value;

    categoriesCache.delete(categoryId);

    this.#categoriesCached.delete(categoryId);

    this.#categoriesCache$.next(categoriesCache);
  }

  hasResultsForEstablishmentId(establishmentId: string): boolean {
    return this.#establishmentsCached.has(establishmentId);
  }

  hasResultsForCategoryId(categoryId: string): boolean {
    return this.#categoriesCached.has(categoryId);
  }

  getCategoryById$(categoryId: string): Observable<Category | undefined> {
    return this.#categoriesCache$.asObservable().pipe(
      filter(() => this.hasResultsForCategoryId(categoryId)),
      map(categoriesCache => {
        return categoriesCache.get(categoryId);
      }),
    );
  }

  getCategoriesByEstablishment$(establishmentId: string): Observable<Category[]> {
    return this.#categoriesCache$.asObservable().pipe(
      filter(() => this.hasResultsForEstablishmentId(establishmentId)),
      map(categoriesCache =>
        this.getCategoriesByEstablishmentImpl(establishmentId, categoriesCache),
      ),
    );
  }

  getCategoriesByEstablishmentSync(categoryId: string): Category[] {
    return this.getCategoriesByEstablishmentImpl(categoryId, this.#categoriesCache$.value);
  }

  private getCategoriesByEstablishmentImpl(
    establishmentId: string,
    categoriesCache: Map<string, Category | undefined>,
  ): Category[] {
    const categoriesByEstablishment = [];

    for (const category of categoriesCache.values()) {
      if (category && category.establishmentId === establishmentId) {
        categoriesByEstablishment.push(category);
      }
    }
    return categoriesByEstablishment.sort((categoryA, categoryB) =>
      categoryA.sortOrder < categoryB.sortOrder ? -1 : 1,
    );
  }
}
