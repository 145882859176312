import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="orders"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconOrdersComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.0625 9.875H11.9375C11.04 9.875 10.3125 10.5979 10.3125 11.4896V37.3229C10.3125 38.2146 11.04 38.9375 11.9375 38.9375H33.0625C33.96 38.9375 34.6875 38.2146 34.6875 37.3229V11.4896C34.6875 10.5979 33.96 9.875 33.0625 9.875Z"
      stroke="#FF7C7C"
      stroke-width="3"
      stroke-linejoin="round"
    />
    <path
      d="M15.9375 32.375H22.5M17.5781 7.0625V11.9617V7.0625ZM27.4219 7.0625V11.9617V7.0625ZM15.9375 19.3105H29.0625H15.9375ZM15.9375 25.8427H25.7812H15.9375Z"
      stroke="#FF7C7C"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>`,
})
export class IconOrdersComponent extends IconComponent {}
