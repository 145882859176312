@if (routerLink || href) {
  @if (routerLink) {
    <a class="wrapper color-{{ color }}" [routerLink]="routerLink">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  } @else {
    <a class="wrapper color-{{ color }}" [href]="href" target="_blank">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
} @else {
  <div class="wrapper color-{{ color }}">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </div>
}

<ng-template #itemContent>
  @if (icon) {
    <ng-content select="pxw-icon"></ng-content>
  }
  <span class="text"><ng-content></ng-content></span>
</ng-template>
