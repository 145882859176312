<ng-content></ng-content>
<dx-tooltip
  [target]="target"
  [(visible)]="visible"
  [hideOnOutsideClick]="false"
  [wrapperAttr]="{ class: 'ui-tooltip__content ' + (ready ? 'ready' : '') }"
  (onShowing)="onShowingTooltip($event)"
  (onHidden)="onHiddenTooltip($event)"
>
  <dxo-position
    my="bottom center"
    at="top center"
    collision="fit flip"
    [of]="target"
    [offset]="{ x: 0, y: offsetY }"
  ></dxo-position>
  <div *dxTemplate="let data of 'content'">
    {{ text }}
    <ng-template [ngTemplateOutlet]="tooltipContent"></ng-template>
  </div>
</dx-tooltip>
