import { inject } from '@angular/core';
import { Injectable, Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AngularUiFormConfig, TranslateTextParams } from '@pedix-workspace/angular-ui-form';
import { EstablishmentService } from './features/catalog/establishment.service';
import { environment } from '../environments/environment';
import { googleTranslateText } from '@pedix-workspace/utils';

@Injectable()
export class AppUiFormConfig extends AngularUiFormConfig {
  private injector = inject(Injector);
  private t = inject(TranslocoService);

  constructor() {
    super();
  }

  get establishmentService(): EstablishmentService {
    return this.injector.get(EstablishmentService);
  }

  getCurrencySymbol() {
    return this.establishmentService.currencyLocaleData.currencySymbol;
  }

  getUseInputDecimals() {
    return this.establishmentService.currencyLocaleData.decimalsLength > 0;
  }

  getValidationRequiredText() {
    return this.t.translate('validation.required');
  }

  getMaskInvalidFormatText(): string {
    return this.t.translate('validation.invalidMask');
  }

  getRequiredLanguagesText(languages: string[]): string {
    return this.t.translate('validation.requiredLanguages', {
      languages: languages.join(',').toUpperCase(),
    });
  }

  translateText(translateParams: TranslateTextParams): Promise<string> {
    return googleTranslateText(environment.firebase.apiKey, translateParams).then(
      translation => translation.slice(0, 1).toUpperCase() + translation.slice(1),
    );
  }
}
