import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="badge"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconBadgeComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.7502 27.9169C25.4271 27.9169 27.5972 25.7469 27.5972 23.07C27.5972 20.3931 25.4271 18.2231 22.7502 18.2231C20.0734 18.2231 17.9033 20.3931 17.9033 23.07C17.9033 25.7469 20.0734 27.9169 22.7502 27.9169Z"
      />
      <path
        d="M26.1876 7.29169C26.1876 6.6839 25.9461 6.101 25.5164 5.67122C25.0866 5.24145 24.5037 5 23.8959 5H21.6042C20.9964 5 20.4135 5.24145 19.9837 5.67122C19.5539 6.101 19.3125 6.6839 19.3125 7.29169V14.1668H26.1876V7.29169Z"
      />
      <path
        d="M13.8815 33.6462C13.6867 33.8812 13.5811 34.1774 13.5835 34.4827V37.0837H31.9171V34.4483C31.9205 34.1429 31.8148 33.8463 31.6192 33.6118C30.5072 32.373 29.1362 31.3941 27.6035 30.7445C26.0707 30.095 24.4138 29.7908 22.7503 29.8534C21.0841 29.7951 19.4256 30.1047 17.8927 30.7602C16.3598 31.4158 14.9902 32.4011 13.8815 33.6462Z"
      />
      <path
        d="M34.2086 9.5834H28.4794V11.8751H34.2086V39.3754H11.2917V11.8751H17.0209V9.5834H11.2917C10.6839 9.5834 10.101 9.82485 9.67122 10.2546C9.24145 10.6844 9 11.2673 9 11.8751V39.3754C9 39.9832 9.24145 40.5661 9.67122 40.9959C10.101 41.4257 10.6839 41.6671 11.2917 41.6671H34.2086C34.8164 41.6671 35.3993 41.4257 35.8291 40.9959C36.2589 40.5661 36.5003 39.9832 36.5003 39.3754V11.8751C36.5003 11.2673 36.2589 10.6844 35.8291 10.2546C35.3993 9.82485 34.8164 9.5834 34.2086 9.5834Z"
      />
    </svg>
  `,
})
export class IconBadgeComponent extends IconComponent {}
