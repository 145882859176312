import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="rocket"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconRocketComponent }],
  template: ` <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.0088 5.69693C36.0483 5.25786 34.0456 5.03534 32.0366 5.03335C30.1393 4.91288 28.2368 5.16868 26.4387 5.78604C24.6406 6.40339 22.9823 7.37013 21.5591 8.63064C19.6847 10.505 17.8279 12.3734 15.9885 14.2361C13.8755 14.0091 8.86378 13.8869 5.51097 17.2746C5.18573 17.6018 5.00317 18.0444 5.00317 18.5057C5.00317 18.967 5.18573 19.4096 5.51097 19.7368L25.2437 39.5044C25.5708 39.8297 26.0134 40.0122 26.4748 40.0122C26.9361 40.0122 27.3787 39.8297 27.7059 39.5044C31.1111 36.0119 31.0063 31.0875 30.7968 29.0269L36.4022 23.4389C41.9728 17.8683 39.4407 7.40826 39.3359 6.9717C39.2553 6.65673 39.0884 6.37053 38.8539 6.14531C38.6194 5.92009 38.3267 5.7648 38.0088 5.69693ZM33.8876 20.9767L27.671 27.1584C27.4696 27.3617 27.3213 27.6114 27.2391 27.8854C27.1569 28.1594 27.1433 28.4495 27.1995 28.73C27.5767 31.0662 27.2484 33.4618 26.2565 35.6103L9.3702 18.6891C11.5528 17.6846 13.9926 17.3796 16.3552 17.8159C16.6348 17.8523 16.9189 17.8205 17.1835 17.7232C17.4481 17.6259 17.6851 17.466 17.8745 17.2571C17.8745 17.2571 20.3192 14.7251 24.0038 11.0405C26.2695 9.22107 29.1379 8.32311 32.0366 8.52586C33.4 8.53262 34.7607 8.64941 36.1054 8.87511C36.5245 11.3723 37.188 17.6762 33.8876 20.9767Z"
    />
    <path
      d="M28.9456 19.5273C30.8745 19.5273 32.4381 17.9636 32.4381 16.0347C32.4381 14.1059 30.8745 12.5422 28.9456 12.5422C27.0168 12.5422 25.4531 14.1059 25.4531 16.0347C25.4531 17.9636 27.0168 19.5273 28.9456 19.5273Z"
    />
    <path
      d="M10.2081 29.4809C6.71558 31.2272 6.71558 38.2122 6.71558 38.2122C9.94802 38.12 13.0425 36.8822 15.4469 34.7197L10.2081 29.4809Z"
    />
  </svg>`,
})
export class IconRocketComponent extends IconComponent {}
