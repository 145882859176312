<ng-container *transloco="let t">
  <pxw-select-box
    name="selectedEstablishment"
    [label]="label"
    [placeholder]="t('establishmentSelector.title')"
    [options]="sortedEstablishments"
    labelKey="name"
    valueKey="slug"
    [searchable]="false"
    [allowNewValues]="false"
    [ngModel]="selectedEstablishment?.slug"
    [disabled]="loadingRedirect"
    (ngModelChange)="onSelectedEstablishment($event)"
  >
    @if (loadingRedirect) {
      <span class="addon-prepend">
        <pxw-icon icon="loading" [spin]="true" size="sm" />
      </span>
    }
    <ng-template #optionItemTemplate let-establishment>
      <div class="establishment-name">{{ establishment.name }}</div>
      @if (selectedEstablishment?.slug !== establishment.slug) {
        <pxw-button size="sm" color="yellow" [rounded]="true">Seleccionar</pxw-button>
      }
    </ng-template>
  </pxw-select-box>
</ng-container>
