import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Auth, signInWithEmailAndPassword } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private auth = inject(Auth);
  private http = inject(HttpClient);

  constructor() {}

  async login(username: string, password) {
    const result = await signInWithEmailAndPassword(this.auth, username.toLowerCase(), password);

    // Retrieves and sends IdToken to persist session across subdomains
    const idTokenResult = await result.user.getIdTokenResult();

    await this.setOrRefreshUserSession(idTokenResult.token);

    return result;
  }

  async logout() {
    await this.http.post('/auth/logout', {}).toPromise();

    await this.auth.signOut();
  }

  acceptInvitedUserInvitation(inviteId: string, idToken: string) {
    return this.http
      .post('/auth/accept-invitation', {
        inviteId,
        idToken,
      })
      .toPromise();
  }

  setOrRefreshUserSession(idToken: string) {
    return this.http.post('/auth/login', { idToken }).toPromise();
  }
}
