import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="search-engine"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconSearchEngineComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.7698 14.5754V12.666C29.7698 12.491 29.6307 12.3478 29.4607 12.3478H14.6208C14.4507 12.3478 14.3116 12.491 14.3116 12.666V14.5754C14.3116 14.7504 14.4507 14.8936 14.6208 14.8936H29.4607C29.6307 14.8936 29.7698 14.7504 29.7698 14.5754ZM14.6208 18.076C14.4507 18.076 14.3116 18.2192 14.3116 18.3942V20.3036C14.3116 20.4786 14.4507 20.6218 14.6208 20.6218H21.7316C21.9016 20.6218 22.0407 20.4786 22.0407 20.3036V18.3942C22.0407 18.2192 21.9016 18.076 21.7316 18.076H14.6208ZM20.1857 36.0562H11.22V8.05161H32.8615V21.7357C32.8615 21.9107 33.0006 22.0539 33.1706 22.0539H35.3348C35.5048 22.0539 35.6439 21.9107 35.6439 21.7357V6.46044C35.6439 5.75634 35.0913 5.1875 34.4073 5.1875H9.67416C8.99013 5.1875 8.4375 5.75634 8.4375 6.46044V37.6474C8.4375 38.3515 8.99013 38.9203 9.67416 38.9203H20.1857C20.3558 38.9203 20.4949 38.7771 20.4949 38.6021V36.3744C20.4949 36.1994 20.3558 36.0562 20.1857 36.0562ZM37.41 38.1048L33.8044 34.3934C34.6662 33.2199 35.1802 31.7561 35.1802 30.1689C35.1802 26.3023 32.1349 23.1677 28.3786 23.1677C24.6222 23.1677 21.577 26.3023 21.577 30.1689C21.577 34.0354 24.6222 37.17 28.3786 37.17C29.7621 37.17 31.0451 36.7444 32.1195 36.0164L35.7792 39.7835C35.841 39.8472 35.9183 39.875 35.9956 39.875C36.0729 39.875 36.1541 39.8432 36.212 39.7835L37.41 38.5504C37.4386 38.5211 37.4612 38.4864 37.4766 38.4482C37.4921 38.41 37.5 38.369 37.5 38.3276C37.5 38.2862 37.4921 38.2452 37.4766 38.207C37.4612 38.1687 37.4386 38.134 37.41 38.1048ZM28.3786 34.6241C25.9864 34.6241 24.0503 32.6312 24.0503 30.1689C24.0503 27.7065 25.9864 25.7136 28.3786 25.7136C30.7707 25.7136 32.7069 27.7065 32.7069 30.1689C32.7069 32.6312 30.7707 34.6241 28.3786 34.6241Z"
    />
  </svg>`,
})
export class IconSearchEngineComponent extends IconComponent {}
