import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { Router, RouterLink } from '@angular/router';
import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import { NGXLogger } from 'ngx-logger';
import { Establishment } from '@pedix-workspace/utils';
import { EstablishmentService } from '../../catalog/establishment.service';
import {
  IconAlertComponent,
  IconCatalogComponent,
  IconCloneComponent,
  IconFormComponent,
  IconInstagramShopComponent,
  IconTableComponent,
  IconTagsComponent,
} from '@pedix-workspace/angular-ui-icons';

import { TranslocoDirective } from '@ngneat/transloco';
import { LoginService } from '../../auth/login/login.service';
import { AppAdminSessionService } from '../../app-admin-session.service';
import {
  AllowedFeatures,
  AppFeaturesAndRolesService,
} from '../../../app-features-and-roles.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    RouterLink,
    IconCatalogComponent,
    IconFormComponent,
    IconInstagramShopComponent,
    IconTableComponent,
    IconCloneComponent,
    IconTagsComponent,
    IconAlertComponent,
    ModalDialogComponent,
  ],
})
export class SideMenuComponent implements OnInit {
  private adminSessionService = inject(AppAdminSessionService);

  @ViewChild('logoutDialog')
  logoutDialog: ModalDialogComponent;

  @Input() displayCatalogMenu: boolean;

  @Output() navItemClick = new EventEmitter();

  displayClonningMenu: boolean;
  displayCatalogConfigMenu: boolean;
  allowedFeatures: AllowedFeatures;

  get establishment() {
    return this.establishmentService.currentEstablishment;
  }
  get cloneTargets(): Establishment['cloneTargets'] | undefined {
    return this.establishment.cloneTargets;
  }

  private router = inject(Router);
  private establishmentService = inject(EstablishmentService);
  private loginService = inject(LoginService);
  private logger = inject(NGXLogger);
  private analyticsService = inject(AnalyticsService);
  private featuresAndRolesService = inject(AppFeaturesAndRolesService);

  ngOnInit(): void {
    this.displayClonningMenu = Array.isArray(this.cloneTargets) && this.cloneTargets.length > 0;

    this.allowedFeatures = this.featuresAndRolesService.getAllowedFeatures();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onNavItemClick(event) {
    this.navItemClick.emit();
  }

  onSideMenuItemClick(menuSection: string) {
    this.analyticsService.logEvent('sidemenu_item_clicked', { menu_option: menuSection });
  }
}
