<ng-container *transloco="let t">
  @if (loadingEstablishments) {
    <div class="ui-loader ui-loader--with-overlay">
      <pxw-icon icon="loading" [spin]="true"></pxw-icon>
    </div>
  }
  <pxw-modal-dialog
    [title]="t('establishmentSelector.title')"
    type="modal"
    [showCloseButton]="showCloseButton && !loadingRedirect"
  >
    <pxw-icon icon="building" />
    @if (establishments) {
      <ng-template #modalContent>
        <div class="content">
          <pxw-alert type="info" size="sm">
            {{ t('establishmentSelector.intro') }}
          </pxw-alert>
          <app-establishment-selector-input
            [establishments]="establishments"
            [loadingRedirect]="loadingRedirect"
            (selectedEstablishmentChange)="onSelectedEstablishment($event)"
          />
        </div>
      </ng-template>
    }
  </pxw-modal-dialog>
</ng-container>
