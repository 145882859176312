import { Component, ViewChild, OnInit, inject, signal, OnDestroy, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { Establishment } from '@pedix-workspace/utils';
import { IconBuildingComponent, IconLoadingComponent } from '@pedix-workspace/angular-ui-icons';
import { EstablishmentService } from '../../catalog/establishment.service';
import { AppUserService } from '../../../app-user.service';
import { AppAdminSessionService } from '../../app-admin-session.service';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { EstablishmentSelectorInputComponent } from '../establishment-selector-input/establishment-selector-input.component';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';

@Component({
  selector: 'app-establishment-selector',
  templateUrl: './establishment-selector.component.html',
  styleUrls: ['./establishment-selector.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    IconLoadingComponent,
    ModalDialogComponent,
    IconBuildingComponent,
    EstablishmentSelectorInputComponent,
    AlertComponent,
  ],
})
export class EstablishmentSelectorComponent implements OnInit, OnDestroy {
  @ViewChild(ModalDialogComponent)
  establishmentSelectorDialog: ModalDialogComponent;

  @Input() showCloseButton = false;

  private router = inject(Router);
  private establishmentService = inject(EstablishmentService);
  private userService = inject(AppUserService);
  private appAdminSessionService = inject(AppAdminSessionService);

  #loadingRedirect = signal(false);
  #establishments = signal<Establishment[]>(null);

  get establishments() {
    return this.#establishments();
  }
  get loadingEstablishments() {
    return this.#establishments() === null;
  }
  get loadingRedirect() {
    return this.#loadingRedirect();
  }

  async ngOnInit() {
    const establishments = await this.establishmentService.getEstablishmentsForInvitedUser(
      this.userService.user.uid,
    );
    if (establishments.length === 1) {
      this.router.navigate(['/inicio']);

      return;
    }
    this.#establishments.set(establishments);

    this.establishmentSelectorDialog.open();
  }

  ngOnDestroy(): void {
    this.establishmentSelectorDialog?.close();
  }

  onSelectedEstablishment(establishment: Establishment) {
    this.#loadingRedirect.set(true);

    this.appAdminSessionService.selectedEstablishment = establishment.slug;

    this.router.navigate(['/inicio']);
  }
}
