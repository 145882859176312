import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="eye"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconEyeComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.0144 21.3318C37.7762 22.3282 37.7762 23.6734 37.0144 24.6682C34.615 27.8005 28.8582 34.25 22.1371 34.25C15.416 34.25 9.65925 27.8005 7.25979 24.6682C6.88915 24.1911 6.68796 23.6042 6.68796 23C6.68796 22.3958 6.88915 21.8089 7.25979 21.3318C9.65925 18.1995 15.416 11.75 22.1371 11.75C28.8582 11.75 34.615 18.1995 37.0144 21.3318V21.3318Z"
      stroke="#FF7C7C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.1371 27.8214C24.7999 27.8214 26.9585 25.6628 26.9585 23C26.9585 20.3372 24.7999 18.1786 22.1371 18.1786C19.4743 18.1786 17.3157 20.3372 17.3157 23C17.3157 25.6628 19.4743 27.8214 22.1371 27.8214Z"
      stroke="#FF7C7C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>`,
})
export class IconEyeComponent extends IconComponent {}
