import { Injectable } from '@angular/core';
import { EnvironmentService } from '@pedix-workspace/angular-utils';
import { environment } from '../environments/environment';

@Injectable({providedIn: 'root'})
export class AppEnvironmentService extends EnvironmentService {
  
  constructor() {
    super();

    const devPortAdmin = '4201';
    const baseUrl = location.href;
    const isLocalEnv = new URL(baseUrl).port === devPortAdmin;

    this.env = environment.production ? 'production' : 'development';
    this.siteUrl = isLocalEnv ? 'http://pedix.local:4200' : environment.siteUrl;
    this.isAdminApp = true;
    this.gmapsApiKey = environment.firebase.apiKey;
  }
}