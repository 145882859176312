import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="analytics"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconAnalyticsComponent }],
  encapsulation: ViewEncapsulation.None,
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.64286 27.2857V29.4286H14.5564L7.5 36.485L9.015 38L16.0714 30.9436V35.8571H18.2143V27.2857H9.64286ZM37.5 10.1429H30V12.2857H33.8421L25.7143 20.4136L21.1146 15.8139C20.9137 15.6131 20.6412 15.5002 20.3571 15.5002C20.073 15.5002 19.8006 15.6131 19.5996 15.8139L13.9286 21.485L15.4436 23L20.3571 18.0864L24.9568 22.6861C25.1577 22.8869 25.4302 22.9998 25.7143 22.9998C25.9984 22.9998 26.2709 22.8869 26.4718 22.6861L35.3571 13.8007V17.6429H37.5V10.1429ZM22.5 35.8571H37.5V38H22.5V35.8571ZM7.5 8H9.64286V23H7.5V8Z"
      />
    </svg>
  `,
})
export class IconAnalyticsComponent extends IconComponent {}
