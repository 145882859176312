import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="store"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconStoreComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.5622 8.69148C34.2615 8.19578 33.8374 7.78548 33.3308 7.50015C32.8242 7.21482 32.2521 7.06409 31.6699 7.0625H13.3301C12.1523 7.0625 11.0436 7.68658 10.4378 8.69148L5.86631 16.2643C5.70812 16.525 5.62467 16.8238 5.625 17.1283C5.63174 18.7476 6.23165 20.3089 7.3125 21.5203V35.5822C7.3125 37.4327 8.82619 38.9375 10.6875 38.9375H34.3125C36.1738 38.9375 37.6875 37.4327 37.6875 35.5822V21.5203C38.7684 20.3089 39.3683 18.7476 39.375 17.1283C39.3753 16.8238 39.2919 16.525 39.1337 16.2643L34.5622 8.69148ZM35.973 17.5494C35.8688 18.3594 35.4716 19.1039 34.8554 19.6439C34.2393 20.1839 33.4464 20.4824 32.625 20.4836C30.7637 20.4836 29.25 18.9787 29.25 17.1283C29.25 17.0142 29.2078 16.9136 29.1842 16.8062L29.2179 16.7995L27.9338 10.4178H31.6699L35.973 17.5494ZM20.5071 10.4178H24.4913L25.8632 17.2373C25.8041 19.0358 24.3225 20.4836 22.5 20.4836C20.6775 20.4836 19.1959 19.0358 19.1368 17.2373L20.5071 10.4178ZM13.3301 10.4178H17.0662L15.7838 16.7995L15.8175 16.8062C15.7922 16.9136 15.75 17.0142 15.75 17.1283C15.75 18.9787 14.2363 20.4836 12.375 20.4836C11.5536 20.4824 10.7607 20.1839 10.1446 19.6439C9.52844 19.1039 9.13116 18.3594 9.027 17.5494L13.3301 10.4178ZM19.125 35.5822V30.5493H25.875V35.5822H19.125ZM29.25 35.5822V30.5493C29.25 28.6989 27.7363 27.1941 25.875 27.1941H19.125C17.2637 27.1941 15.75 28.6989 15.75 30.5493V35.5822H10.6875V23.6006C11.2292 23.7398 11.7878 23.8388 12.375 23.8388C13.3332 23.8393 14.2804 23.6365 15.1535 23.2439C16.0265 22.8513 16.8052 22.278 17.4375 21.5623C18.6744 22.9581 20.4868 23.8388 22.5 23.8388C24.5132 23.8388 26.3256 22.9581 27.5625 21.5623C28.1948 22.278 28.9735 22.8513 29.8465 23.2439C30.7196 23.6365 31.6668 23.8393 32.625 23.8388C33.2122 23.8388 33.7708 23.7398 34.3125 23.6006V35.5822H29.25Z"
    />
  </svg>`,
})
export class IconStoreComponent extends IconComponent {}
