export function getFileExtensionFromMimeType(mimeType: string): string {
  const mimeTypesMap = {
    'text/html': 'html',
    'text/css': 'css',
    'text/xml': 'xml',
    'image/gif': 'gif',
    'image/jpeg': 'jpg',
    'application/x-javascript': 'js',
    'text/plain': 'txt',

    'image/png': 'png',
    'image/tiff': 'tif',
    'image/vnd.wap.wbmp': 'wbmp',
    'image/x-icon': 'ico',
    'image/x-jng': 'jng',
    'image/x-ms-bmp': 'bmp',
    'image/svg+xml': 'svg',
    'image/webp': 'webp',

    'application/msword': 'doc',
    'application/pdf': 'pdf',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/x-rar-compressed': 'rar',
    'application/xhtml+xml': 'xhtml',
    'application/zip': 'zip',

    'audio/midi': 'midi',
    'audio/mpeg': 'mp3',
    'audio/ogg': 'ogg',

    'video/3gpp': '3gpp',
    'video/mpeg': 'mpeg',
    'video/quicktime': 'mov',
    'video/x-flv': 'flv',
    'video/x-mng': 'mng',
    'video/x-ms-wmv': 'wmv',
    'video/x-msvideo': 'avi',
    'video/mp4': 'mp4',
  };

  return mimeTypesMap[mimeType];
}
