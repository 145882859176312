import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="help"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconHelpComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.0321 14.5625C20.1678 14.5645 18.3806 15.1803 17.0623 16.275C15.744 17.3697 15.0024 18.8539 15 20.402H18.5751C18.5751 18.8182 20.1266 17.5312 22.0321 17.5312C23.9377 17.5312 25.4892 18.8182 25.4892 20.402C25.4892 21.2897 24.6294 21.9339 23.3156 22.8156C22.8864 23.0948 22.4741 23.3916 22.0804 23.7048C20.2965 25.1847 20.2446 26.7566 20.2446 26.9318V27.9219H23.8197L23.8179 26.9823C23.8197 26.9585 23.8769 26.4093 24.6062 25.8052C24.8743 25.5825 25.2122 25.3598 25.5625 25.1253C26.955 24.1887 29.0625 22.7741 29.0625 20.402C29.0611 18.8539 28.32 17.3695 27.0019 16.2747C25.6838 15.1799 23.8964 14.5641 22.0321 14.5625ZM20.2446 29.4062H23.8197V32.375H20.2446V29.4062Z"
    />
    <path
      d="M22.5 7.0625C13.7121 7.0625 6.5625 14.2121 6.5625 23C6.5625 31.7879 13.7121 38.9375 22.5 38.9375C31.2879 38.9375 38.4375 31.7879 38.4375 23C38.4375 14.2121 31.2879 7.0625 22.5 7.0625ZM22.5 35.75C15.47 35.75 9.75 30.03 9.75 23C9.75 15.97 15.47 10.25 22.5 10.25C29.53 10.25 35.25 15.97 35.25 23C35.25 30.03 29.53 35.75 22.5 35.75Z"
    />
  </svg>`,
})
export class IconHelpComponent extends IconComponent {}
