import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { TranslocoDirective } from '@ngneat/transloco';
import { Establishment } from '@pedix-workspace/utils';
import { IconLoadingComponent } from '@pedix-workspace/angular-ui-icons';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { SelectBoxComponent } from '@pedix-workspace/angular-ui-form-reactive';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-establishment-selector-input',
  templateUrl: './establishment-selector-input.component.html',
  styleUrls: ['./establishment-selector-input.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    IconLoadingComponent,
    ButtonComponent,
    SelectBoxComponent,
    FormsModule,
  ],
})
export class EstablishmentSelectorInputComponent {
  @Input({ required: true }) establishments: Establishment[];
  @Input() selectedEstablishment: Establishment;
  @Input() label: string;

  @Output() selectedEstablishmentChange = new EventEmitter<Establishment>();

  @ViewChild(ModalDialogComponent)
  establishmentSelectorDialog: ModalDialogComponent;

  @Input() showCloseButton = false;
  @Input() loadingRedirect = false;

  get sortedEstablishments() {
    return this.establishments.slice(0).sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  onSelectedEstablishment(slug: string) {
    const selectedEstablishment = this.establishments.find(
      establishment => establishment.slug === slug,
    );

    this.selectedEstablishmentChange.emit(selectedEstablishment);
  }
}
