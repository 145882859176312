<ng-container *transloco="let t">
  <pxw-modal-dialog [title]="t('restrictedAccess.title')" [showCloseButton]="false">
    <pxw-icon icon="ban" />
    <ng-template #modalContent>
      <pxw-alert type="warning" style="margin-bottom: 2em">
        <p>{{ t('restrictedAccess.body1') }}</p>
        <p>{{ t('restrictedAccess.body2') }}</p>
      </pxw-alert>
    </ng-template>
  </pxw-modal-dialog>
</ng-container>
