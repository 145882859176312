import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="caret-down"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconCaretDownComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5537 32.4152L8.14514 17.9883C6.68074 16.2909 7.87089 13.625 10.0934 13.625H34.9105C35.4079 13.6246 35.8949 13.77 36.3131 14.0438C36.7313 14.3176 37.0631 14.7083 37.2687 15.169C37.4742 15.6297 37.5449 16.1409 37.4722 16.6414C37.3995 17.1419 37.1865 17.6104 36.8587 17.9909L24.4501 32.4125C24.2073 32.6952 23.9078 32.9217 23.5718 33.077C23.2358 33.2322 22.871 33.3125 22.5019 33.3125C22.1329 33.3125 21.7681 33.2322 21.4321 33.077C21.096 32.9217 20.7966 32.6952 20.5537 32.4125V32.4152Z"
      />
    </svg>
  `,
})
export class IconCaretDownComponent extends IconComponent {}
