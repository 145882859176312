import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="sign-out"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconSignOutComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.4 14.5019V11.3987C26.4 10.9286 26.3024 10.4636 26.1134 10.0331C25.9245 9.60264 25.6482 9.21602 25.3022 8.8977C24.9561 8.57939 24.5478 8.3363 24.1029 8.1838C23.6581 8.0313 23.1864 7.97271 22.7178 8.01173L9.1178 9.14356C8.26789 9.21432 7.47562 9.60181 6.89811 10.2292C6.3206 10.8566 6.00004 11.678 6 12.5306V33.4677C6.00004 34.3203 6.3206 35.1417 6.89811 35.7691C7.47562 36.3965 8.26789 36.784 9.1178 36.8547L22.7178 37.9883C23.1866 38.0273 23.6584 37.9687 24.1033 37.8161C24.5483 37.6635 24.9567 37.4202 25.3028 37.1017C25.6489 36.7832 25.9251 36.3964 26.1139 35.9657C26.3028 35.535 26.4002 35.0698 26.4 34.5996V31.4964M24.7 22.9992H40H24.7ZM40 22.9992L34.3339 16.2014L40 22.9992ZM40 22.9992L34.3339 29.7969L40 22.9992Z"
      stroke="#FF7C7C"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>`,
})
export class IconSignOutComponent extends IconComponent {}
