import { DOCUMENT, NgClass } from '@angular/common';
import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
  Renderer2,
  OnInit,
  inject,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import { AdminPanelHeaderNavigation, Establishment } from '@pedix-workspace/utils';
import { EstablishmentUrlPipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { ImageStoragePipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { DevicePreviewerComponent } from '@pedix-workspace/angular-ui-device-previewer';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { RouterLink } from '@angular/router';
import {
  IconMenuComponent,
  IconNotificationComponent,
  IconSettingsComponent,
} from '@pedix-workspace/angular-ui-icons';
import { TranslocoDirective } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    IconMenuComponent,
    IconSettingsComponent,
    IconNotificationComponent,
    RouterLink,
    ButtonComponent,
    NgClass,
    DevicePreviewerComponent,
    ImageStoragePipe,
    UserMenuComponent,
  ],
})
export class HeaderComponent implements OnInit {
  @Input() establishment: Establishment;
  @Input() isNuboReady: boolean;
  @Input() invitedUserEstablishments: Establishment[] = [];
  @Input() displayMenuButton: boolean;

  @Output() clickLateralMenu = new EventEmitter();

  isEstablishmentUrlPreloaded = false;
  previewerOpened = false;

  get establishmentName() {
    return this.establishment.name;
  }
  get establishmentLogo() {
    return this.establishment.logo;
  }
  get establishmentUrl() {
    return this.establishmentUrlPipe.transform(this.establishment, ['']);
  }
  get establishmentUrlPreview() {
    return `${this.establishmentUrl}?previewMode=true`;
  }

  private document = inject(DOCUMENT);

  private renderer = inject(Renderer2);
  private analyticsService = inject(AnalyticsService);
  private establishmentUrlPipe = inject(EstablishmentUrlPipe);

  ngOnInit(): void {
    const linkPreconnect = this.document.createElement('link');

    linkPreconnect.rel = 'preconnect';
    linkPreconnect.href = environment.siteUrl;

    this.renderer.appendChild(document.head, linkPreconnect);
  }

  onClickLateralMenu($event) {
    this.clickLateralMenu.emit($event);
  }

  clickOption(header_menu: AdminPanelHeaderNavigation) {
    this.analyticsService.logEvent('adminpanel_headeroptions_clicked', { header_menu });
  }

  onPreviewHover(previewUrl: string) {
    if (this.isEstablishmentUrlPreloaded) {
      return;
    }
    this.isEstablishmentUrlPreloaded = true;

    const linkPreload = this.document.createElement('link');

    linkPreload.rel = 'prefetch';
    linkPreload.href = previewUrl;

    this.renderer.appendChild(document.head, linkPreload);
  }

  onOpenPreviewer() {
    this.previewerOpened = true;
  }

  onClosePreviewer() {
    this.previewerOpened = false;
  }
}
