import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="clone"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconCloneComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.9286 14H18.0714C15.8228 14 14 15.8228 14 18.0714V33.9286C14 36.1772 15.8228 38 18.0714 38H33.9286C36.1772 38 38 36.1772 38 33.9286V18.0714C38 15.8228 36.1772 14 33.9286 14Z"
        stroke="#FF7C7C"
        stroke-width="3"
        stroke-linejoin="round"
      />
      <path
        d="M31.9643 13.7143L32 12C31.997 10.9401 31.5746 9.9244 30.8251 9.1749C30.0756 8.42541 29.0599 8.00302 28 8H12.5714C11.3601 8.00358 10.1994 8.48636 9.3429 9.3429C8.48636 10.1994 8.00358 11.3601 8 12.5714V28C8.00302 29.0599 8.42541 30.0756 9.1749 30.8251C9.9244 31.5746 10.9401 31.997 12 32H13.7143M25.7143 20V31.4286M31.4286 25.7143H20"
        stroke="#FF7C7C"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
})
export class IconCloneComponent extends IconComponent {}
