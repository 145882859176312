import { Injectable } from '@angular/core';
import { Product } from '@pedix-workspace/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductsStoreService {
  private productsCache$ = new BehaviorSubject<Map<string, Product | undefined>>(new Map());

  private categoriesCached: Set<string> = new Set();
  private productsCached: Set<string> = new Set();
  private sharedOptionsCached: Set<string> = new Set();
  private productTagsCached: Set<string> = new Set();

  get loadedProducts$(): Observable<Product[]> {
    return this.productsCache$.asObservable().pipe(
      map(productsCache => {
        const products = [];

        for (const product of productsCache.values()) {
          if (product) {
            products.push(product);
          }
        }
        return products;
      }),
    );
  }

  addProductToCache(productId: string, product: Product | undefined): void {
    this.addProductToCacheImpl(productId, product);

    this.productsCache$.next(this.productsCache$.value);
  }

  addProductsToCache(products: (Product | undefined)[]): void {
    products.forEach(product => {
      if (product) {
        this.addProductToCacheImpl(product.id, product);
      }
    });

    this.productsCache$.next(this.productsCache$.value);
  }

  addCategoryProductsToCache(categoryId: string, products: Product[]) {
    this.categoriesCached.add(categoryId);

    this.addProductsToCache(products);
  }

  addSharedOptionProductsToCache(sharedOptionId: string, products: Product[]) {
    this.sharedOptionsCached.add(sharedOptionId);

    this.addProductsToCache(products);
  }

  addProductTagProductsToCache(productTagId: string, products: Product[]) {
    this.productTagsCached.add(productTagId);

    this.addProductsToCache(products);
  }

  addProductToCacheImpl(productId: string, product: Product | undefined): void {
    const productsCache = this.productsCache$.value;

    productsCache.set(productId, product);

    this.productsCached.add(productId);
  }

  patchProductInCache(productId: string, dataPatch: Partial<Product>): void {
    this.patchProductInCacheImpl(productId, dataPatch);

    this.productsCache$.next(this.productsCache$.value);
  }

  patchProductsInCache(products: ({ id: Product['id'] } & Partial<Product>)[]): void {
    products.forEach(product => {
      this.patchProductInCacheImpl(product.id, product);
    });

    this.productsCache$.next(this.productsCache$.value);
  }

  patchProductInCacheImpl(productId: string, dataPatch: Partial<Product>) {
    const productsCache = this.productsCache$.value;

    const updatedProduct = <Product>{
      ...productsCache.get(productId),
      ...dataPatch,
    };
    productsCache.set(productId, updatedProduct);

    return productsCache;
  }

  removeProductFromCache(productId: string): void {
    const productsCache = this.productsCache$.value;

    productsCache.delete(productId);

    this.productsCached.delete(productId);

    this.productsCache$.next(productsCache);
  }

  hasResultsForProductId(productId: string): boolean {
    return this.productsCached.has(productId);
  }

  hasResultsForCategoryId(categoryId: string): boolean {
    return this.categoriesCached.has(categoryId);
  }

  hasResultsForSharedOptionId(sharedOptionId: string): boolean {
    return this.sharedOptionsCached.has(sharedOptionId);
  }

  hasResultsForProductTagId(productTagId: string): boolean {
    return this.productTagsCached.has(productTagId);
  }

  getProductById$(productId: string): Observable<Product | undefined> {
    return this.productsCache$.asObservable().pipe(
      filter(() => this.hasResultsForProductId(productId)),
      map(productsCache => productsCache.get(productId)),
    );
  }

  getProductsByCategory$(categoryId: string): Observable<Product[]> {
    return this.productsCache$.asObservable().pipe(
      filter(() => this.hasResultsForCategoryId(categoryId)),
      map(productsCache => this.getProductsByCategoryImpl(categoryId, productsCache)),
    );
  }

  getProductsByLinkedSharedOption$(sharedOptionId: string): Observable<Product[]> {
    return this.productsCache$.asObservable().pipe(
      filter(() => this.hasResultsForSharedOptionId(sharedOptionId)),
      map(productsCache => this.getProductsByLinkedSharedOptionImpl(sharedOptionId, productsCache)),
    );
  }

  getProductsByLinkedProductTags$(productTagId: string): Observable<Product[]> {
    return this.productsCache$.asObservable().pipe(
      filter(() => this.hasResultsForProductTagId(productTagId)),
      map(productsCache => this.getProductsByLinkedProductTagImpl(productTagId, productsCache)),
    );
  }

  getProductsByCategorySync(categoryId: string): Product[] {
    return this.getProductsByCategoryImpl(categoryId, this.productsCache$.value);
  }

  private getProductsByCategoryImpl(
    categoryId: string,
    productsCache: Map<string, Product | undefined>,
  ): Product[] {
    const productsByCategory = [];

    for (const product of productsCache.values()) {
      if (product && product.categoryId === categoryId) {
        productsByCategory.push(product);
      }
    }
    return productsByCategory.sort((productA, productB) =>
      productA.sortOrder < productB.sortOrder ? -1 : 1,
    );
  }

  private getProductsByLinkedSharedOptionImpl(
    sharedOptionId: string,
    productsCache: Map<string, Product | undefined>,
  ): Product[] {
    const productsByLinkedSharedOption = [];

    for (const product of productsCache.values()) {
      if (product && product.linkedOptionIds?.includes(sharedOptionId)) {
        productsByLinkedSharedOption.push(product);
      }
    }
    return productsByLinkedSharedOption.sort((productA, productB) =>
      productA.sortOrder < productB.sortOrder ? -1 : 1,
    );
  }

  private getProductsByLinkedProductTagImpl(
    productTagId: string,
    productsCache: Map<string, Product | undefined>,
  ): Product[] {
    const productsByLinkedProductTags = [];

    for (const product of productsCache.values()) {
      if (product && product.linkedProductTagIds?.includes(productTagId)) {
        productsByLinkedProductTags.push(product);
      }
    }
    return productsByLinkedProductTags.sort((productA, productB) =>
      productA.sortOrder < productB.sortOrder ? -1 : 1,
    );
  }
}
