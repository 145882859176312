import { Component, ViewChild, OnDestroy, signal, inject } from '@angular/core';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import {
  IconChangePasswordComponent,
  IconEyeComponent,
  IconEyeSlashComponent,
} from '@pedix-workspace/angular-ui-icons';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';
import { noop } from 'lodash-es';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { InputTextComponent } from '@pedix-workspace/angular-ui-form-reactive';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { AppUserService } from '../../../app-user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    ModalDialogComponent,
    AlertComponent,
    FormsModule,
    InputTextComponent,
    IconEyeComponent,
    IconEyeSlashComponent,
    ReactiveFormsModule,
    ButtonComponent,
    IconChangePasswordComponent,
  ],
})
export class ChangePasswordDialogComponent implements OnDestroy {
  private formBuilder = inject(FormBuilder);
  private userService = inject(AppUserService);
  private toastrService = inject(ToastrService);
  private t = inject(TranslocoService);

  @ViewChild(ModalDialogComponent)
  changePasswordDialog: ModalDialogComponent;

  form: FormGroup<{
    password: FormControl<string>;
    passwordRepeat: FormControl<string>;
  }>;

  password: string;
  repeatPassword: string;

  #revealPassword = signal(false);
  #updatingPassword = signal(false);

  get revealPassword() {
    return this.#revealPassword();
  }
  get updatingPassword() {
    return this.#updatingPassword();
  }

  matchingPasswordsValidator: ValidatorFn = (control: AbstractControl) => {
    const passwordControl = control.get('password');
    const repeatPasswordControl = control.get('passwordRepeat');

    if (passwordControl!.errors && !repeatPasswordControl!.errors?.['passwordMatch']) {
      return null;
    }

    if (passwordControl.value !== repeatPasswordControl.value) {
      const error = { passwordMatch: true };

      repeatPasswordControl.setErrors(error);
    } else {
      repeatPasswordControl.setErrors(null);
      return null;
    }
  };

  ngOnDestroy(): void {
    this.changePasswordDialog?.close();
  }

  open() {
    this.#updatingPassword.set(false);

    this.changePasswordDialog.open().catch(noop);

    this.form = this.formBuilder.group(
      {
        password: new FormControl('', [Validators.minLength(8)]),
        passwordRepeat: new FormControl('', [Validators.minLength(8)]),
      },
      {
        validators: [this.matchingPasswordsValidator],
      },
    );
  }

  close() {
    this.changePasswordDialog.close();
  }

  toggleRevealPassword() {
    this.#revealPassword.update(revealPassword => !revealPassword);
  }

  async onUpdatePassword() {
    if (!this.form.valid) {
      return;
    }
    this.form.disable();

    this.#updatingPassword.set(true);

    try {
      await this.userService.updatePassword(this.form.get('password').value);

      this.toastrService.success(this.t.translate('Contraseña actualizada con éxito'));

      this.changePasswordDialog.close();
    } catch (error) {
      console.error(error);

      this.toastrService.error(this.t.translate('error.retryLater'));
    }
    this.#updatingPassword.set(false);

    this.form.enable();
  }
}
