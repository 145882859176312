import { Injectable, inject } from '@angular/core';
import { TranslocoLoader } from '@ngneat/transloco';

import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);
  
  constructor() {}

  getTranslation(lang: 'AR' | 'MX') {
    // If the running context isE2E, then we won't load translations
    if (environment.isE2E) {
      return of({ e2e: 'true ' });
    }

    const languageKey = lang === 'AR' ? 'es-AR' : `es-MX`;

    return this.http.get(`/assets/i18n/admin/${languageKey}.json`);
  }
}
