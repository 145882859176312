<ng-container *transloco="let t">
  <span class="ui-item-link admin-action user" rel="nofollow">
    <div
      #dropdown="ngbDropdown"
      ngbDropdown
      [autoClose]="true"
      placement="bottom-right"
      [popperOptions]="popperOptions"
    >
      <div class="user-avatar" [ngClass]="{ 'with-photo': user.photoURL }" ngbDropdownToggle>
        @if (isEstablishmentUser) {
          <pxw-icon icon="user" size="sm" />
        } @else if (user.photoURL) {
          <img class="user-image" [src]="user.photoURL" [alt]="userInitials" />
        } @else if (userInitials) {
          <span class="user-initials">
            {{ userInitials }}
          </span>
        } @else {
          <pxw-icon icon="user" size="sm" />
        }
      </div>
      <div class="menu-dropdown" ngbDropdownMenu>
        <div class="menu-section user-details">
          @if (isEstablishmentUser) {
            <label class="ui-label ui-label--xs">Tienda Pedix:</label>
            <strong class="establishment-name">{{ establishment.name }}</strong>
          } @else {
            {{ user.displayName }}

            <pxw-tag size="sm" type="info" [uppercase]="false">{{ user.email }}</pxw-tag>
          }

          <app-user-invite-roles [userRoles]="userRoles" />
        </div>

        @if (isInvitedUser) {
          <div class="dropdown-divider"></div>
          <div class="menu-section">
            @if (invitedUserEstablishments.length > 1) {
              <app-establishment-selector-input
                [label]="'Tienda Pedix'"
                [establishments]="invitedUserEstablishments"
                [selectedEstablishment]="establishment"
                [loadingRedirect]="loadingRedirect"
                (selectedEstablishmentChange)="onEstablishmentSelected($event)"
              />
            } @else {
              <label class="ui-label ui-label--xs">Tienda Pedix:</label>
              <strong class="establishment-name">{{ establishment.name }}</strong>
            }
          </div>
        }
        @if (isEstablishmentUser) {
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem class="menu-button" (click)="onUpdateContactInfo()">
            <pxw-icon size="xs" icon="form" />
            {{ t('updateContactInfoDialog.title') }}
          </button>
        }
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem class="menu-button" (click)="onChangePassword()">
          <pxw-icon size="xs" icon="change-password" />
          {{ t('Actualizar contraseña') }}
        </button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem class="menu-button logout" (click)="logout()">
          <pxw-icon size="xs" icon="sign-out" />
          {{ t('Cerrar sesión') }}
        </button>
      </div>
      <div class="ui-dropdown__backdrop" [hidden]="!dropdown.isOpen()"></div>
    </div>
  </span>
  <app-change-password-dialog />
  <app-update-contact-info-form />
</ng-container>
