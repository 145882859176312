<ng-container *transloco="let t">
  <pxw-modal-dialog [title]="t('Actualizar contraseña')" [showCloseButton]="false">
    <pxw-icon icon="change-password" />
    <ng-template #modalContent>
      <form id="change-password-form" [formGroup]="form" (ngSubmit)="onUpdatePassword()">
        <pxw-input-text
          name="password"
          formControlName="password"
          [type]="revealPassword ? 'text' : 'password'"
          [label]="t('Nueva contraseña')"
          [displayErrors]="true"
        >
          <span class="addon-append ui-clickable" (click)="toggleRevealPassword()">
            @if (revealPassword) {
              <pxw-icon icon="eye-slash" />
            } @else {
              <pxw-icon icon="eye" />
            }
          </span>
        </pxw-input-text>
        <pxw-input-text
          name="passwordRepeat"
          formControlName="passwordRepeat"
          [type]="revealPassword ? 'text' : 'password'"
          [label]="t('Repetir contraseña')"
          [displayErrors]="true"
          [messages]="{
            passwordMatch: t('Las contraseñas no coinciden')
          }"
        >
          <span class="addon-append ui-clickable" (click)="toggleRevealPassword()">
            @if (revealPassword) {
              <pxw-icon icon="eye-slash" />
            } @else {
              <pxw-icon icon="eye" />
            }
          </span>
        </pxw-input-text>
      </form>
    </ng-template>
    <ng-template #modalButtons let-close="close">
      <pxw-button
        size="md"
        color="blue"
        [rounded]="true"
        [disabled]="!form.valid"
        [appareance]="'outline'"
        (click)="close()"
      >
        Cancelar
      </pxw-button>
      <pxw-button
        formId="change-password-form"
        mode="submit"
        size="md"
        color="blue"
        [rounded]="true"
        [loading]="updatingPassword"
        [disabled]="!form.valid"
        (click)="onUpdatePassword()"
      >
        Actualizar
      </pxw-button>
    </ng-template>
  </pxw-modal-dialog>
</ng-container>
