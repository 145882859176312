import { Component, ViewChild, OnDestroy, inject, AfterViewInit, Input } from '@angular/core';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { TranslocoDirective } from '@ngneat/transloco';
import { IconBanComponent } from '@pedix-workspace/angular-ui-icons';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';
import { AppUserService } from '../../../app-user.service';
import { LoginService } from '../../auth/login/login.service';
import { Router } from '@angular/router';
import { Establishment } from '@pedix-workspace/utils';

@Component({
  selector: 'app-invited-user-should-logout-dialog',
  templateUrl: './invited-user-should-logout-dialog.component.html',
  styleUrls: ['./invited-user-should-logout-dialog.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, ModalDialogComponent, AlertComponent, IconBanComponent],
})
export class InvitedUserShouldLogoutDialogComponent implements AfterViewInit, OnDestroy {
  private loginService = inject(LoginService);
  private router = inject(Router);
  private userService = inject(AppUserService);

  @ViewChild(ModalDialogComponent)
  dialog: ModalDialogComponent;

  @Input() establishment: Establishment;

  get isInvitedUser() {
    return this.userService.isInvitedUser;
  }

  ngAfterViewInit() {
    this.dialog.open(async () => {
      this.loginService.logout();

      if (this.isInvitedUser) {
        this.router.navigate(['/acceso/invitados'], {
          queryParams: {
            email: this.userService.user.email,
          },
        });
      } else {
        this.router.navigate(['/acceso'], {
          queryParams: {
            username: this.establishment.slug,
          },
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.dialog?.close();
  }
}
