import { Injectable, inject } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { catchError, delay, map, retryWhen, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppTranslationsGuard  {
  private translocoService = inject(TranslocoService);
  
  constructor() {}

  canActivate(): Observable<boolean> {
    return this.translocoService.selectTranslation().pipe(
      map(data => {
        if (Object.keys(data).length === 0) {
          throw new Error();
        }
        return Object.keys(data).length > 0;
      }),
      retryWhen(errors => errors.pipe(delay(500), take(20))),
      catchError(() => {
        throw new Error(`Language file couldn't be loaded`);
      }),
    );
  }
}
