import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="subscription"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconSubscriptionComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.2188 8H10.4688C8.82109 8 7.5 9.32109 7.5 10.9688V27.2969C7.5 28.9445 8.82109 30.2656 10.4688 30.2656H16.4063V37.6875L22.3438 34.7188L28.2813 37.6875V30.2656H34.2188C35.8664 30.2656 37.1875 28.9445 37.1875 27.2969V10.9688C37.1875 9.32109 35.8664 8 34.2188 8ZM34.2188 27.2969H10.4688V24.3281H34.2188V27.2969ZM34.2188 19.875H10.4688V10.9688H34.2188V19.875Z"
    />
  </svg>`,
})
export class IconSubscriptionComponent extends IconComponent {}
