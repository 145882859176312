import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="tablet"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconTabletComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 12.9375L5 33.3542C5 34.3211 5.38411 35.2484 6.06784 35.9322C6.75157 36.6159 7.6789 37 8.64583 37L36.3542 37C36.8329 37 37.307 36.9057 37.7494 36.7225C38.1917 36.5393 38.5936 36.2707 38.9322 35.9322C39.2707 35.5936 39.5393 35.1917 39.7225 34.7494C39.9057 34.307 40 33.8329 40 33.3542L40 12.9375C40 12.4587 39.9057 11.9846 39.7225 11.5423C39.5393 11.1 39.2707 10.6981 38.9322 10.3595C38.5936 10.021 38.1917 9.75241 37.7494 9.56919C37.307 9.38597 36.8329 9.29166 36.3542 9.29166L8.64583 9.29167C7.6789 9.29167 6.75157 9.67578 6.06784 10.3595C5.38411 11.0432 5 11.9706 5 12.9375ZM38.5417 23.1458C38.5417 24.3562 37.5646 25.3333 36.3542 25.3333C35.1438 25.3333 34.1667 24.3562 34.1667 23.1458C34.1667 21.9354 35.1438 20.9583 36.3542 20.9583C37.5646 20.9583 38.5417 21.9354 38.5417 23.1458ZM32.7083 12.2083L32.7083 34.0833L9.375 34.0833L9.375 12.2083L32.7083 12.2083Z"
    />
  </svg>`,
})
export class IconTabletComponent extends IconComponent {}
